@import 'src/assets/scss/mixins';

.tag {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-violet;
  color: $color-white;
  font-size: 0.75em;
  line-height: 1.6em;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 3px;

  &__text {
    margin-right: 10px;
    max-width: 100%;
    line-break: anywhere;
  }

  &__button {
    font-weight: 700;
    height: 15px;
    padding: 3px 4px 2px 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    &:hover {
      color: $color-violet;
      background-color: $color-white;
    }
  }
}
