@import 'src/assets/scss/mixins';

.sticky-wrapper {
  width: 100%;
  max-height: calc(100vh - 70px);
  min-height: calc(100vh - 70px);
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 35px;
  overflow-y: auto;
  overflow-x: visible;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  @include size('tablet') {
    display: none;
  }

  .menu {
    width: 100%;

    &__sticky {
      position: fixed;
      width: 185px;
      top: 10px;
      left: 40px;
      right: 0;
    }
  }
}
