@import 'src/assets/scss/mixins';

.layout {
  width: 100%;

  &-inner {
    height: 100%;
    max-width: 960px;
    overflow: hidden;
  }
}
