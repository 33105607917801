@import 'src/assets/scss/mixins';

.progress {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &-box {
    min-width: 440px;
    height: auto;
    padding: 40px;
    border-radius: 20px;
    background: $color-white;

    @include size('phone') {
      min-width: 90%;
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      color: $color-violet;
    }

    &__svg {
      display: block;
      margin: 20px auto;
      max-width: 100%;

      &-circle-bg {
        fill: none;
      }

      &-circle {
        fill: none;
      }

      &-circle-text {
        font-size: 1.5rem;
        text-anchor: middle;
        fill: $color-violet;
      }
    }

    &__close {
      margin-top: 20px;
      text-align: center;
      padding: 15px 30px;
      color: $color-error;
      cursor: pointer;
    }
  }
}
