@import 'src/assets/scss/mixins';

.home__content {
  width: 100%;
  min-height: 100%;

  &--inner {
    width: 100%;

    &__title {
      &-element {
        margin: 30px 0;
      }
    }

    &__nav {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      max-width: 900px;

      &-item {
        text-decoration: none;
        display: block;
        height: 126px;
        width: 430px;
        flex: 0 auto;
        margin: 15px 10px;

        @include size('laptop') {
          width: 100%;
        }
      }

      &-pop-up {
        padding-left: 24px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
