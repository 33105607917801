@import 'src/assets/scss/mixins';

.sponsorship-menu {
  width: 100%;
  padding-left: 20px;
  &__item {
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      display: flex;
      align-items: center;
      width: 80%;
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
      span {
        max-width: 85px;
      }
      .ad-icon {
        display: flex;
        align-items: center;
      }
      &.active {
        * {
          color: $color-violet;
        }
        .ad-icon {
          svg * {
            stroke: $color-violet;
          }
        }
        .expand-icon {
          svg {
            stroke: transparent;
            path {
              stroke: transparent;
              fill: $color-violet;
            }
          }
        }
      }
      &.disabled {
        * {
          color: $color-gray;
        }
        .ad-icon {
          svg * {
            stroke: $color-gray;
          }
        }
        .expand-icon {
          svg {
            stroke: transparent;
            path {
              stroke: transparent;
              fill: $color-gray;
            }
          }
        }
      }
    }
  }
  .open {
    display: flex;
    svg {
      transition: all 0.15s;
      transform: rotate(90deg);
    }
  }
  .closed {
    display: flex;
    svg {
      transition: all 0.15s;
      transform: rotate(0);
    }
  }
}

.sponsorship-submenu {
  padding: 0;
  list-style: none;
  margin-top: 0px;
  margin-left: 25px;

  &__item {
    text-transform: none;
    font-size: 0.8em;
    font-weight: 400;
  }
}
