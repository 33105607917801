@import 'src/assets/scss/mixins';

.rejection {
  &-title {
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-text {
    margin-bottom: 20px;
  }
}

.completion-title {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
}
