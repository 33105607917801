.stripe {
  &-cancel-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      padding: 0;
      margin: 0 auto 20px 0;
    }

    &__support-link {
      padding-left: 7px;
      margin: 0;
    }
  }
}
