@import 'src/assets/scss/mixins';

.switch {
  width: 35px;
  height: 23px;
  position: relative;

  &__menu {
    // height: 16px;
  }

  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &-label {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border: 3px solid $color-black;
    border-radius: 13px;

    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      background: $color-red;
      border-radius: 50%;
      margin-left: 2px;
      transition: all 0.2s ease;
    }

    &__disabled {
      opacity: 0.5;
      cursor: not-allowed;
      border-color: $color-darkGray;
      &::before {
        background: $color-gray !important;
      }
    }

    &__menu {
      background: $color-darkGray;
      border: none;
      height: 11px;
      width: 23px;

      &:before {
        position: absolute;
        left: -4px;
      }
    }
  }
}

.switch-input:checked + .switch-label {
  &:before {
    background: $color-green;
    margin-left: 12px;
    transition: all 0.2s ease;
  }
}

.switch-input:checked + .switch-label__menu {
  &:before {
    background: $color-green;
    margin-left: 14px;
    transition: all 0.2s ease;
  }
}
