@import 'src/assets/scss/mixins';

.tile {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid $color-white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;

      &:hover {
        border: 2px solid transparent;
      }
    }

    &:hover,
    &--checked,
    &--disabled-checked {
      border: 2px solid $color-violet;
    }

    &-icon__wrapper {
      flex-shrink: 0;
      width: 85px;
      margin-right: 16px;

      &-icon {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-violet;
        color: $color-white;
      }
    }

    &-content {
      color: $color-black;
      text-decoration: none;

      &__title {
        font-size: 1.25em;
        letter-spacing: 0.75px;
      }

      &__subtitle {
        font-size: 0.75em;
        line-height: 1.5em;
        letter-spacing: 0.5px;
      }
    }
  }
}
