@import 'src/assets/scss/mixins';

.delivery {
  &__content,
  &__accented {
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-bottom: 10px;

    .highlighted {
      font-size: 1.777em;
      line-height: 30px;
      font-weight: 500;
      letter-spacing: 0.25px;
      text-decoration: underline;
    }
  }

  &__accented {
    background-color: $color-lightBlue;
    border-left: 2px solid $color-gray;
    padding: 10px;
    font-style: italic;
  }
}
