@import 'src/assets/scss/mixins';

.container {
  margin-top: 26px;

  .download-container {
    margin-top: 26px;
    display: flex;
    align-items: center;
    text-decoration: none;

    .details {
      margin-left: 20px;
      color: black;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
      }
      .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }
    }
    .download {
      &__button {
        border: 0;
        background-color: transparent;
        color: $color-bright-violet;
      }
    }
  }
}
