@import 'src/assets/scss/mixins';

.dropdown-title {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $color-violet;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  .open {
    display: flex;
    svg {
      path {
        fill: $color-violet;
      }
      transition: all 0.15s;
      transform: rotate(90deg);
    }
  }
  .closed {
    display: flex;
    svg {
      path {
        fill: $color-violet;
      }
      transition: all 0.15s;
      transform: rotate(0);
    }
  }
  .adset-icon {
    display: flex;
    svg {
      rect {
        stroke: $color-violet;
      }
      path {
        fill: $color-violet;
      }
    }
  }
  .ad-icon {
    display: flex;
    svg:first-child {
      stroke: $color-violet;
      path {
        stroke: $color-violet;
      }
      circle {
        stroke: $color-violet;
      }
    }
  }
  span {
    margin-left: 3px;
  }
}
