@import 'src/assets/scss/mixins';

.box__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 auto;
  padding: 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: border-color 200ms ease;
  color: $color-black;
  cursor: pointer;

  .csv-link {
    &:hover {
      border-color: $color-violet;
    }

    .download-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      color: $color-darkGray;

      &:hover {
        color: $color-violet;
      }

      .download-text {
        font-size: small;
        margin-right: 10px;
      }
    }

    &--disabled {
      opacity: 0.3;

      .download-icon {
        cursor: not-allowed;

        &:hover {
          color: $color-darkGray;
        }
      }
    }
  }
}
