@import 'src/assets/scss/mixins';

.strength-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  .strength-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: relative;

    .bar {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: $color-gray;

      &.very-weak {
        background-color: $color-error;
      }
      &.weak {
        background-color: $color-warning;
      }
      &.good {
        background-color: $color-violet;
      }
      &.strong {
        background-color: $color-green;
      }
    }
  }

  .text {
    text-transform: capitalize;
    text-align: right;
  }
}
