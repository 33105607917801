@import 'src/assets/scss/mixins';

.general-sidebar {
  position: fixed;
  min-height: 100vh;
  max-height: 100vh;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @include size('tablet') {
    display: none;
  }

  &__burger {
    right: -10px;
    top: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999999px;
    position: absolute;
    cursor: pointer;
    padding: 0.3rem;
    background-color: $color-white;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 0px 8px 0px;
  }

  &__content {
    background: $color-white;
    width: 100%;
    height: 100%;
    max-width: 225px;
    border: 1px none $color-black;
    overflow: hidden;

    &--active {
      min-height: 100vh;
      max-height: 100vh;
    }
  }
}
