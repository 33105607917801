@import 'src/assets/scss/mixins';

.billing {
  margin-top: 75px;

  &__info-boxes {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 960px;
    margin-top: 30px;

    &__item {
      width: 390px;
      padding: 10px 10px 10px 10px;

      @include size('tablet') {
        width: 100%;
      }

      .item__body {
        margin-top: 20px;
        min-height: 80px;

        .sum {
          font-size: 2.6em;
          line-height: 1.5em;
          font-weight: 400;
          letter-spacing: 0.5px;
        }

        .card {
          display: flex;
          align-items: center;

          &-img {
            margin-right: 20px;
            display: flex;
            align-items: center;
          }

          &-info {
            &__number {
              .mask {
                display: inline-flex;
                align-items: center;
              }
            }
          }
        }
      }

      .item__link {
        .link {
          display: block;
          color: $color-violet;
          text-align: right;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  &__request-invoice {
    margin-top: 40px;

    &__content {
      margin-bottom: 5px;
    }
  }

  &__history {
    margin: 70px 0;

    &-title {
      margin-bottom: 30px;
      font-size: 1.333em;
      font-weight: 700;
      letter-spacing: 0.75px;
    }

    &-empty {
      font-size: 1em;
      line-height: 1.4em;
      margin-bottom: 70px;
    }
  }
  .link {
    font-weight: 700;
    text-decoration: underline;
    color: $color-violet;
    cursor: pointer;
  }

  .campaign-link {
    font-weight: 700;
    text-decoration: underline;
    color: $color-violet;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
  }

  &__no-charges {
    margin-top: 20px;
    min-height: 80px;
  }
}
