@import 'src/assets/scss/mixins';

.arrow-wrapper {
  width: 20px;
  height: 20px;
  display: none;

  @include size('tablet') {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 25px;
    right: 20px;
    z-index: 3000;
  }

  .arrow {
    width: 10px;
    height: 10px;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    transition: 0.1s ease-in-out;

    &-down {
      transform: rotate(-45deg);
    }

    &-up {
      transform: rotate(135deg);
    }
  }
}

.mobile-menu {
  position: absolute;
  background-color: $color-lightGray;
  width: 100%;
  z-index: 1009;
  overflow: hidden;
  left: 0px;

  &__open {
    padding: 0 50px 30px 50px;
    position: fixed;
    top: 70px;
    visibility: visible;
    transform: translateX(0%);
    transition: transform 0.4s ease-in-out;
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    -webkit-transform: translateY(0%);
  }

  &__close {
    padding: 0 50px 30px 50px;
    visibility: hidden;
    position: fixed;
    transform: translateX(-100%);
    transition: transform 0.4s ease-in-out, visibility 0s 0.4s;
    -webkit-transition: -webkit-transform 0.4s ease-in-out, visibility 0s 0.4s;
    -webkit-transform: translateY(-100%);
  }
}
