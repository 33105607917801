@import 'src/assets/scss/mixins';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.reset-password-reset {
  &__container {
    background-color: $color-black-new;
    color: $color-yellow;
    * {
      font-family: 'Montserrat', sans-serif;
    }
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 320px;
    padding: 1.5rem 0;

    input {
      background-color: $color-black-new;
      border-color: $color-darkGray;
      color: $color-white !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px #23232329;
      }
    }

    &-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 320px;

      &-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $color-white;
      }
    }
    &-formgroup-1 {
      width: 100%;
      margin-top: 0;
    }

    &-formgroup-2 {
      width: 100%;
      margin-top: 17px;
    }

    &-button {
      margin-top: 1.5rem;
      width: 100%;

      button {
        background-color: $color-black-new;
        color: $color-yellow;
        border-color: $color-yellow;
        border-radius: 3px;
      }
    }
  }
}
