@import 'src/assets/scss/mixins';

.admin-panel {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;

  &__feedback-link {
    display: block;
    margin-right: 30px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    background-color: transparent;
    color: $color-black;
    font-size: 0.75em;
    text-decoration: underline;

    @include size('phone-big') {
      display: none;
    }
  }

  &__default-logo {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    margin-right: 10px;
    position: relative;

    &--image {
      width: 100%;
      height: 100%;
      display: block;
      border: 1px solid $color-black;
      border-radius: 50%;
    }

    &--status {
      position: absolute;
      left: 28px;
      top: 20px;
    }
  }

  &__brand-name {
    font-size: 0.8em;
    line-height: 100%;
    font-weight: 400;
    margin-right: 30px;
  }

  &__balance {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 0.8em;
    line-height: 100%;

    &-name {
      font-weight: 700;
      margin-right: 10px;

      @include size('phone') {
        display: none;
      }
    }
  }

  &__notifications,
  &__settings {
    cursor: pointer;

    & svg {
      &:hover {
        color: $color-violet;
      }
    }
  }

  &__notifications {
    margin-right: 30px;
  }

  &__settings {
    position: relative;
  }

  &__logout {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    font-weight: 500;
    color: $color-violet;
    cursor: pointer;
    span {
      @include size('tablet') {
        display: none;
      }
    }
    svg {
      width: 15px;
      height: 15px;
      fill: $color-violet;
    }
  }
}
