@import 'src/assets/scss/mixins';

.tags-generator {
  width: 100%;

  &__input-wrap {
    position: relative;

    .tags-input {
      border: none;
      padding: 7px 12px;

      &::-webkit-input-placeholder {
        color: $color-darkGray;
        font-size: 0.75em;
        line-height: 100%;
        letter-spacing: 1px;
      }
    }
  }

  .options-container {
    position: absolute;
    width: 100%;
    max-height: 188px;
    overflow-y: auto;
    background-color: $color-white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
    top: 50px;

    &__option,
    &__empty {
      padding: 7px 12px;

      font-weight: 400;
      min-height: 45px;
      display: flex;
      align-items: center;
    }

    &__option {
      cursor: pointer;
      font-size: 0.85em;

      &:hover {
        background-color: $color-violet;
        color: $color-white;
      }
    }

    &__empty {
      text-align: center;
      color: $color-darkGray;
      font-size: 0.75em;
    }
  }

  &__tags-battery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &__tag-wrapper {
      margin: 5px;
    }
  }

  &__input-error {
    color: $color-error;
    padding: 0 0 0 10px;
    margin: 0;
    font-size: 0.75em;
    min-height: 1.3rem;
  }
}
