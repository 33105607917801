@import 'src/assets/scss/mixins';

.card {
  width: 100%;

  &-type__battery {
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-input__title {
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  &-input__wrapper {
    width: 100%;
  }

  &-input__section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0 25px 0;

    &-expiration {
      width: 55%;
    }

    &-cvc {
      width: 35%;
    }
  }

  &-button {
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;

    &__inner {
      width: 50%;
    }
  }
}

.stripe-general--error {
  width: 100%;
  text-align: center;
  color: $color-error;
  font-size: 0.75em;
  margin-bottom: 25px;
}
