.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba(102, 115, 251, 0.2);
  border-right: 0.2em solid white;
  border-bottom: 0.2em solid white;
  border-left: 0.2em solid white;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
  border-radius: 50%;
  width: 2em;
  height: 2em;

  &:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
