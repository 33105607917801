@import 'src/assets/scss/mixins';

.status {
  width: 13px;
  height: 13px;
  border: 4px solid $color-black;
  border-radius: 50%;

  &-active {
    border-color: $color-37d45a;
  }

  &-inactive {
    border-color: $color-979797;
  }

  &-review {
    border-color: $color-yellow;
  }

  &-rejected {
    border-color: $color-f03737;
  }
  &-draft {
    border-color: $color-bright-violet;
  }
}
