@import 'src/assets/scss/mixins';

.alt-wrapper {
  width: 100%;
  overflow-y: hidden;

  &__inner {
    width: 100%;
    padding: 0px 35px;

    @include size('phone') {
      padding-left: 20px;
      padding-right: 20px;
      overflow: hidden;
    }
  }

  @include size('tablet') {
    padding-left: 0px !important;
  }
}
