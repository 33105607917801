@import 'src/assets/scss/mixins';
.link {
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  color: $color-violet;
  text-decoration: underline;
}

.transactions {
  &__title {
    margin: 50px 0;
  }

  &__no-data {
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }
}
