@import 'src/assets/scss/mixins';

.detail {
  &__container {
    width: 100%;
    max-width: 350px;
    min-width: 220px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 auto;
    padding: 10px 0 10px 10px;
    margin-right: 20px;
    border-left: 2px solid $color-gray;
  }

  &__content {
    font-size: 0.85em;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-left: 10px;

    &-line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;

      &:hover {
        overflow: visible;
        -webkit-line-clamp: none;
        display: block;
      }
    }
  }
}
