@import 'src/assets/scss/mixins';

.box {
  width: 100%;
  height: 188px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 0 0 2px $color-violet;
  }

  &-title {
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.75px;
  }

  &-content {
    font-size: 0.75em;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  &.disabled {
    *:not(.box-disabled-content, .title, .message-unverified, .message-pending) {
      filter: blur(4px);
      pointer-events: none;
      opacity: 0.9;
    }
    background-color: rgba(0, 0, 0, 0.024);
    cursor: initial;
    &:hover {
      border: none;
    }
  }

  &-disabled-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-weight: 500;
    .title {
      font-size: 1.1rem;
    }
    .message-unverified {
      color: $color-violet;
      font-size: 0.9rem;
    }
    .message-pending {
      @extend .message-unverified;
      text-decoration: none;
      color: $color-warning;
    }
  }
}
