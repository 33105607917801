@import 'src/assets/scss/mixins';

.copy-container {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .copy-icon {
    cursor: pointer;
    background-color: $color-violet;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    width: 20px;
    height: 20px;
    svg {
      color: white;
      width: 10px;
      height: 10px;
    }
  }
}
