@import '~cropperjs/dist/cropper.css';
@import '../mixins';

img {
  display: block;
  max-width: 100%;
}

.cropper-modal {
  background-color: transparent;
}
