@import 'src/assets/scss/mixins';

.error-container {
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  .inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    max-width: 600px;
    gap: 2rem;
    .header {
      font-size: 2.5rem;
    }
    .home-button {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
}
