@import 'src/assets/scss/mixins';

.settings-menu {
  width: 100%;
  margin: 10px 0;

  @include size('tablet') {
    margin-top: 40px;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;

    &-item {
      margin: 15px 0;

      &-link {
        text-decoration: none;
        color: $color-darkGray;
        font-size: 0.8em;
        font-weight: 400;

        &:hover,
        &:focus,
        &--active {
          color: $color-violet;
        }
      }
    }
  }
}
