@import 'src/assets/scss/mixins';

.main-menu {
  display: block;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    &-item {
      margin: 0;

      &:hover {
        color: $color-violet;
      }

      &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 14px;
        line-height: 100%;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: $color-darkGray;
        padding: 18px 20px 18px 25px;

        &:hover,
        &:focus,
        &--active {
          color: $color-violet;
        }

        &--active {
          border-right: 6px solid $color-violet;
        }
      }

      &-icon {
        margin-right: 15px;
      }
    }
  }
}
