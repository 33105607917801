@import 'src/assets/scss/mixins';

.menu-options {
  all: initial;
  cursor: pointer;
  margin-left: auto;
  margin-top: 2px;
  &.open {
    svg * {
      fill: $color-violet;
    }
  }
  svg {
    pointer-events: none;
  }
  &.disabled {
    svg * {
      fill: $color-gray;
    }
  }
  svg {
    pointer-events: none;
  }
}
