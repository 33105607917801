@import 'src/assets/scss/mixins';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  @include size('phone-big') {
    justify-content: center;
    align-items: center;
  }
}

.modal {
  position: relative;
  z-index: 102;
  transform: scale(0);

  &--active {
    overflow-y: auto;
    max-height: 80%;
    width: auto;
    max-width: 80%;
    min-width: 300px;
    min-height: 300px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transform: scale(1);
    animation: blowUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

    @include size('phone-big') {
      width: 90%;
    }

    .inner {
      padding: 20px;
      background-color: $color-white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .bar {
          width: 1.5rem;
          height: 0.25rem;
          background: $color-white;
          border-radius: 10px;
          position: relative;

          &:first-child {
            transform: rotate(45deg);
            top: 11px;
          }

          &:last-child {
            transform: rotate(-45deg);
            bottom: 1px;
          }
        }
      }

      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn-wrap {
        width: 50%;
        min-width: 250px;
        margin-top: 20px;

        @include size('tablet') {
          width: 100%;
        }
      }
    }
  }
}

@-webkit-keyframes blowUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blowUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
