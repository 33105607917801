@font-face {
  font-family: 'Nootype radikal';
  src: url('../fonts/Nootype---Radikal-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Nootype radikal';
  src: url('../fonts/Nootype---Radikal.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Nootype radikal';
  src: url('../fonts/Nootype---Radikal-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Space grotesk';
  src: url('../fonts/SpaceGrotesk-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Space grotesk';
  src: url('../fonts/SpaceGrotesk-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Space grotesk';
  src: url('../fonts/SpaceGrotesk-Light.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Space grotesk';
  src: url('../fonts/SpaceGrotesk-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
