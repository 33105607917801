@import 'src/assets/scss/mixins';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.reset-password-success {
  &__container {
    background-color: $color-black-new;
    color: $color-yellow;
    * {
      font-family: 'Montserrat', sans-serif;
    }
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 320px;
    padding: 1.5rem 0;

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $color-white;
    }

    &-subtitle {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 20px;
      color: $color-white;
    }

    &-input {
      margin-top: 0.75rem;
    }

    &-button {
      margin-top: 1.5rem;
      width: 100%;
      button {
        background-color: $color-black-new;
        color: $color-yellow;
        border-color: $color-yellow;
        border-radius: 3px;
      }
    }

    &-body {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 20px;
      color: $color-white;
    }

    &-resend {
      font-weight: 400;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      color: $color-white;

      &__link {
        border: none;
        background-color: transparent;
        font-weight: 700;
        color: $color-yellow;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
