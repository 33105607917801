@import 'src/assets/scss/mixins';

.grid-wrapper {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: auto;
  grid-column-gap: 5px;
  grid-template-areas:
    'Topbar Topbar'
    'Sidebar Content';

  & > div:nth-child(1) {
    grid-area: Topbar;
  }

  & > div:nth-child(2) {
    grid-area: Sidebar;
    padding-top: $top-bar-height;
    z-index: 1;
    @include size('tablet') {
      padding-top: 0;
    }
  }

  & > div:nth-child(3) {
    grid-area: Content;
    padding-top: $top-bar-height;
    @include size('tablet') {
      padding-top: 80px;
    }
  }

  @include size('tablet') {
    grid-template-columns: 1fr 0;
    grid-template-areas:
      'Topbar'
      'Sidebar'
      'Content';
  }

  &--active {
    height: 100vh;
  }
}
