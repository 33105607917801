@import 'src/assets/scss/mixins';

.sponsor-set {
  &__inner {
    width: 100%;
    // max-width: 450px;

    .timing {
      max-width: 450px;
      scroll-margin-top: $top-bar-height;
      &__subtitle {
        font-size: 0.75em;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.25px;
        width: 95%;
        margin-bottom: 10px;
      }

      &__area {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include size('phone-big') {
          width: 100%;
          display: block;
        }

        &-input,
        &-range {
          position: relative;

          @include size('phone-big') {
            width: 100%;
            max-width: 100%;
            min-height: 50px;
          }
        }

        &-input {
          flex-basis: 45%;
          max-width: 45%;

          @include size('phone-big') {
            width: 100%;
            max-width: 100%;
          }
        }

        &-range {
          padding-top: 15px;

          @include size('phone-big') {
            text-align: center;
          }
        }
      }

      &__error {
        color: $color-error;
        font-size: 0.75em;
        text-align: right;
      }

      &__date-to-wrap {
        position: relative;

        > div {
          > div {
            z-index: 1;
          }
        }

        > svg {
          position: absolute;
          right: 19px;
          top: 14px;
          pointer-events: none;
          z-index: 1;
        }

        &:hover {
          > svg {
            color: $color-violet;
          }
        }
      }

      &__date-from-wrap {
        position: relative;

        > svg {
          position: absolute;
          right: 19px;
          top: 14px;
          pointer-events: none;
          z-index: 1;
        }

        &:hover {
          > svg {
            color: $color-violet;
          }
        }
      }
    }

    .campaign-type {
      &__subtitle {
        font-size: 0.75em;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.25px;
        margin-bottom: 20px;
      }
    }

    .tab-section {
      display: flex;
      border-bottom: 1px solid $color-thinGray;

      .button-wrapper {
        flex-basis: 50%;
      }
    }
  }
}
