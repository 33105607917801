@import 'src/assets/scss/mixins';

.error {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__message {
    width: 80%;
    font-size: 0.75em;
    color: $color-error;
    text-align: right;
  }
}
