@import 'src/assets/scss/mixins';

.campaign {
  margin-top: 75px;

  &__unsaved {
    color: $color-error;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    gap: 5px;
    span {
      margin-top: 3px;
    }

    ul {
      padding-left: 1rem;
    }

    &-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
  }

  &__name {
    scroll-margin-top: 70px;
  }

  &__brand-logo {
    max-width: 500px;
    width: 100%;
    .upload-container {
      display: flex;
      align-items: center;
    }
  }

  &__objective {
    width: 100%;

    &-inner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__buttons {
    margin: 50px 0 150px 0;
  }
  .pixel-doc-link {
    text-decoration: none;
    color: $color-violet;
  }

  .charity {
    width: 450px;

    .popup {
      color: $color-violet;
      margin-left: 4px;
      font-size: 12px;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }

    &__inn {
      width: 100%;
      margin: 10px 0 0 15px;
      padding: 5px 15px 15px 10px;
      border-left: 2px solid $color-gray;

      &-section {
        .title {
          font-size: 0.75em;
          line-height: 30px;
          font-weight: 700;
          letter-spacing: 0.25px;
          margin-bottom: 5px;
        }

        .add {
          font-size: 0.75em;
          line-height: 20px;
          font-weight: 400;
          letter-spacing: 0.25px;
          margin-top: 10px;
        }
      }

      &-section:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
