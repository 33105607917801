@import 'src/assets/scss/mixins';

.billing-report {
  &__title {
    margin: 50px 0;
  }

  &__table {
    margin-bottom: 70px;
  }

  &__no-data {
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }
}
