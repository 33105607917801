@import 'src/assets/scss/mixins';

.menu {
  padding: 0;
  list-style: none;
  margin-top: 0px;
  margin-left: 25px;
  margin-bottom: 5px;
  color: $color-black;

  &__item {
    text-transform: none;
    font-size: 0.8em;
    font-weight: 400;
  }
}
