.highcharts-container {
  position: absolute;
  width: 100% !important;
}

.highcharts-credits {
  display: none;
}

div[data-highcharts-chart] {
  width: 100%;
}

.highchart-donut {
  &__label-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 4.5rem;

    .label-number {
      font-size: 14px;
    }

    .label-text {
      font-size: 12px;
    }
  }
}

.highchart-map {
  &__tooltip {
    letter-spacing: 0.5px;
    font-size: 10px;

    &-name {
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
}

.highchart-spline {
  &__tooltip {
    &-header {
      letter-spacing: 0.5px;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    &-row {
      margin-top: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__circle {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 5px;
      }

      &__content {
        font-size: 10px;
        letter-spacing: 0.5px;
      }
    }
  }
}

.highchart-bar {
  &__tooltip {
    &-figure {
      margin-bottom: 5px;
      letter-spacing: 0.5px;
      font-weight: 600;
    }

    &-percent {
      letter-spacing: 0.5px;
      font-weight: 600;
    }
  }
}

.emoji {
  font-size: 13px;
}
