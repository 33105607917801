@import 'src/assets/scss/mixins';

.title {
  width: 80%;

  &__content {
    line-height: 1.3em;
    font-weight: 700;
    letter-spacing: 0.6px;
    color: $color-black;
  }
}
