.objectives {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 960px;
  }
}
