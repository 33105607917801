@import 'src/assets/scss/mixins';

.horizontal {
  height: 1px;
  background-color: $color-black;
  border: 0;
  margin: 1rem 0;
}

.summary-menu {
  width: 100%;

  &__item {
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}
