@import 'src/assets/scss/mixins';

.zone {
  max-width: 450px;
  margin-top: 20px;
  margin-bottom: 20px;

  &__inner {
    max-width: 328px;
    border: 1px dashed $color-black;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &--error {
      border: 1px dashed $color-error;
    }

    &--circle {
      width: 200px;
      height: 200px;
      min-height: 200px;
      border-radius: 100px;
    }

    &--rect {
      width: 100%;
      height: 234px;
    }

    &--portrait {
      aspect-ratio: 9/16;
      width: 234px;
    }

    &--square {
      width: 300px;
      height: 340px;
      min-height: 300px;
      min-width: 300px;
    }

    &--small-square {
      width: 185px;
      height: 185px;
      min-height: 185px;
      min-width: 185px;
    }

    &--circle,
    &--rect,
    &--square,
    &--portrait,
    &--small-square {
      .dropzone {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        scroll-margin-top: 100px;

        .image-wrapper {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;

          .image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .video-wrapper {
          width: 100%;
          height: calc(100% - 45px);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-direction: column;
          background-color: #000000;

          .player {
            position: relative;
            top: 0;
            left: 0;

            video {
              object-fit: cover;
            }
          }
        }

        .file-wrapper {
          width: 100%;
          height: 100%;
          display: flex;

          .file-icon {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $color-violet;
            color: $color-white;
          }
        }
      }
    }

    .text {
      color: $color-darkGray;
      font-size: 0.75em;
      font-weight: 400;
      line-height: 160%;
      max-width: 140px;
      text-align: center;
      margin-top: 5px;
    }

    .icon {
      text-align: center;
    }

    .select-file-btn {
      font-size: 0.75em;
      height: 50px;
    }
  }
}
