@import 'src/assets/scss/mixins';

.input-field {
  width: 100%;
  max-width: 480px;
  position: relative;

  &__draggable {
    &:before {
      content: url('../../../assets/images/dragging-bars.svg');
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 10px;
      z-index: 10;
      background-color: $color-mildGray;
      cursor: pointer;
      height: 48px;
    }

    & input {
      padding-left: 20px;

      & ~ label {
        left: 20px;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 15px;
    right: 1rem;
    cursor: pointer;
  }

  &__link {
    position: absolute;
    top: 15px;
    height: 20px;
    right: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: $color-violet;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    border: none;
    background-color: transparent;

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
      color: black;
    }
  }

  &__input {
    border: 1px solid $color-black;
    position: relative;
    scroll-margin-top: 70px;

    &:not(:focus) {
      ~ .input-field__label--active {
        top: 8px;
        font-size: 0.5em;
      }
    }

    &--error {
      border: 1px solid $color-error;
    }

    &--disabled {
      opacity: 0.3;
    }
    &--readonly {
      pointer-events: none;
      border: 1px solid $color-mildGray;
    }
    &--icon {
      padding-right: 3rem !important;
    }
  }

  &__container {
    position: relative;
    &__searchable {
      position: absolute;
      width: 100%;
      height: auto;
      left: 0;
      top: 100%;
      border-radius: 0 0 0.3rem 0.3rem;
      overflow: hidden;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 2px 4px 12px;
      display: flex;
      flex-direction: column;
      z-index: 9999;
      max-height: 250px;
      overflow-y: auto;
      &--item {
        cursor: pointer;
        padding: 0.8rem;
        font-size: 0.85rem;
        min-width: 100% !important;
        &[active],
        &:hover {
          background-color: $color-mildGray;
        }
      }
    }
  }
}
