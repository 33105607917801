@import 'variables';

@mixin size($breakpoints) {
  @if $breakpoints == 'phone' {
    @media (max-width: 480px) {
      @content;
    }
  } @else if $breakpoints == 'phone-big' {
    @media (max-width: 600px) {
      @content;
    }
  } @else if $breakpoints == 'tablet-graph' {
    @media (max-width: 810px) {
      @content;
    }
  } @else if $breakpoints == 'tablet' {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoints == 'laptop' {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoints == 'desktop' {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $breakpoints == 'desktop-mid' {
    @media (max-width: 1550px) {
      @content;
    }
  } @else if $breakpoints == 'big' {
    @media (max-width: 1800px) {
      @content;
    }
  }
}

@mixin font-size($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
}
