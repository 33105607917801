@import 'src/assets/scss/mixins';

.mini-modal {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background: $color-white;
  width: auto;
  padding: 11px 15px;
  position: absolute;
  border-radius: 3px;
  bottom: -17px;
  right: 26px;
  z-index: 30;

  &__content {
    min-width: 120px;

    &--not-active {
      opacity: 0.3;
      pointer-events: none;

      &:hover {
        color: transparent;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:hover {
      color: $color-violet;
    }
  }
}
