@import 'src/assets/scss/mixins';

.total {
  &__content {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    &-header {
      cursor: pointer;
      width: 90%;
      font-size: 1.25em;
      font-weight: 400;
      letter-spacing: 0.75px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-number {
      cursor: pointer;
      display: block;
      width: 100%;
      margin: 20px 0;
      align-self: auto;
      font-size: 1.777em;
      font-weight: 500;
      letter-spacing: 1px;
      color: $color-violet;
    }

    &-counter {
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;

      &__container {
        display: flex;
        margin-right: 20px;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        flex: 0 0 auto;
      }

      &__title {
        font-size: 0.563em;
        line-height: 150%;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__number {
        font-size: 1em;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: capitalize;
      }
    }
  }
}
