@import 'src/assets/scss/mixins';

.delete-button {
  width: 50%;
  cursor: pointer;
  color: $color-red;
  border: none;
  background-color: $color-white;

  &__disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
