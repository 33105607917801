@import 'src/assets/scss/mixins';

.tab,
.tab-active {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 400;
  cursor: pointer;

  &-active {
    background: $color-lightGray;
    color: $color-violet;
  }
}
