@import 'src/assets/scss/mixins';

.reporting__hero,
.reporting__hero-reverse {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 40px;
  align-items: center;

  @include size('laptop') {
    display: block;
    width: 70%;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  @include size('phone') {
    width: 100%;
  }

  .content {
    grid-area: Text;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include size('phone-big') {
      min-width: unset;
      width: 100%;
    }

    &--title {
      height: auto;
      // margin: 0 0 8px 0;
      font-size: 1.25em;
      line-height: 1.2em;
      font-weight: 400;
      letter-spacing: 0.6px;
      align-self: flex-start;
    }

    &--subtitle {
      display: inline-block;
      align-self: flex-start;
      font-weight: 400;
      letter-spacing: 0.5px;
      font-size: 1em;
      line-height: 1.5em;
      margin-top: 0.5rem;
    }

    &--value {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1.5rem;
    }
  }

  .image-wrapper,
  .image-wrapper__reverse {
    grid-area: Image;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @include size('laptop') {
      display: block;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .image-block {
      width: 100%;
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }
}

.reporting__hero {
  grid-template-areas: 'Text Image';
}

.reporting__hero-reverse {
  margin-top: 20px;
  grid-template-areas: 'Image Text';
}
