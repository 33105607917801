@import 'src/assets/scss/mixins';

.card {
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;

  &-type {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &-title {
    font-size: 0.75em;
    line-height: 100%;
    letter-spacing: 0.25px;
    color: $color-979797;
  }

  &-number {
    font-size: 1em;
    letter-spacing: 1px;
    margin: 5px 0;
    color: $color-white;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__edit-icon {
      cursor: pointer;
      position: relative;
    }
  }

  &-default {
    background-color: $color-black;
    border: 4px solid $color-black;
  }

  &-navy {
    background-color: $color-navy;
    border: 4px solid $color-navy;
  }

  &-green {
    background-color: $color-darkGreen;
    border: 4px solid $color-darkGreen;
  }

  &-red {
    background-color: $color-darkRed;
    border: 4px solid $color-darkRed;
  }
}

.active {
  border: 4px solid $color-violet;
  position: relative;

  &:before {
    content: 'active';
    position: absolute;
    top: -1px;
    left: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    background-color: $color-violet;
    font-size: 0.75em;
    line-height: 100%;
    letter-spacing: 0.25px;
    width: 63px;
    height: 20px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
