@import 'src/assets/scss/mixins';

.alt-sidebar {
  background: white;
  width: 225px;
  position: fixed;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @include size('tablet') {
    width: 100%;
    min-height: unset;
    margin-top: 0;
    padding: 0;
    position: relative;
  }
}
