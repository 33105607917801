@import 'src/assets/scss/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: $color-black;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
}
