@import 'src/assets/scss/mixins';

.invoice-request {
  &__subheader,
  &__input-title {
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  &__input-title {
    margin-top: 20px;
  }

  &__buttons {
    margin: 40px 0 12px 0;
    display: flex;
    justify-content: center;
  }
}
