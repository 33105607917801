@import 'src/assets/scss/mixins';
.table-wrap {
  width: 100%;
  overflow-x: auto;

  .table {
    width: 100%;
    display: table;
    overflow-x: auto;

    &-head,
    &-head-sticky {
      width: 100%;
      display: table-header-group;
      z-index: 3;
      &__cell {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding: 20px 10px 20px 10px;
        font-size: 0.85em;
        font-weight: 600;
        line-height: 14px;
        min-width: 100px;
        z-index: 2;
        border-bottom: 1px solid $color-black;
        background-color: $color-white;
      }
    }

    &-head-sticky {
      position: fixed;
      top: 70px;
    }

    &-body {
      display: table-row-group;
    }

    &-row {
      display: table-row;
      width: 100%;
    }

    &-cell,
    &-cell__budget,
    &-cell__link,
    &-cell__status,
    &-cell__actions {
      max-width: 190px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      padding: 10px 10px;
      font-size: 0.85em;
      font-weight: 400;
      line-height: 20px;
      border-bottom: 1px solid $color-black;

      &:first-child {
        border-right: 1px solid $color-lightGray;
      }

      &__link {
        min-width: 200px;
        max-width: 220px;

        .name {
          width: 100%;
          margin-top: 10px;
          color: $color-black;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-all;

          &:hover {
            text-decoration: none;
            color: $color-violet;
            overflow: visible;
            -webkit-line-clamp: none;
            display: block;
          }
        }

        .no-link {
          margin-top: 10px;
        }

        .reporting {
          width: 100%;
          height: 30px;
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 0 5px;
          font-size: 0.75em;
          line-height: 100%;
          letter-spacing: 1px;
          color: $color-darkGray;

          &-performance,
          &-demographics {
            position: relative;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 2px solid transparent;
            text-decoration: none;
            color: $color-darkGray;
            cursor: pointer;

            &:hover {
              border-bottom: 2px solid $color-violet;
            }
          }

          &-demographics {
            margin-left: 10px;
          }

          &-duplicate {
            position: relative;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 2px solid transparent;
            text-decoration: none;
            color: $color-darkGray;
            cursor: pointer;

            &:hover {
              border-bottom: 2px solid $color-violet;
            }

            &__content {
              display: flex;
              flex-direction: row;
            }
          }

          &-duplicate {
            margin-left: 10px;
          }

          .link {
            text-decoration: none;
            color: $color-darkGray;
            margin-left: 7px;
            margin-top: 3px;
          }

          .disabled {
            color: rgb(189, 189, 189);
            cursor: not-allowed;
            // pointer-events: none;
            * {
              color: rgb(189, 189, 189);
            }
            &:hover {
              border-bottom: none !important;
            }
          }
        }
      }

      &__status {
        min-width: 100px;

        &-inner {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .status-info {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .status-text {
              margin: 3px 0 0 5px;
              text-transform: capitalize;
            }
          }

          .reason-link {
            color: $color-violet;
            cursor: pointer;
            font-size: 0.75em;
            line-height: 100%;
            letter-spacing: 1px;
            margin-top: 12px;
            align-self: center;
          }
        }
      }

      &__budget {
        text-align: left;
      }

      &__switch {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__questions {
        width: 100%;
        min-width: 100px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

        .link {
          color: $color-violet;
          cursor: pointer;
        }
      }

      &__actions {
        .archive-section {
          .unarchive {
            svg {
              fill: $color-green;
            }
          }
          .archive {
            fill: $color-red;
          }
          button {
            all: initial;
            cursor: pointer;
            svg {
              height: 30px;
              width: 30px;
            }
            * {
              font-family: 'Space grotesk', -apple-system, BlinkMacSystemFont, avenir next, avenir,
                helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
            }
          }
        }
      }
    }

    .cell-inner {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    .cell-media {
      width: 70px;
      height: 70px;
      border: 1px dashed $color-darkGray;
      margin: 0 auto;
      display: flex;
      align-items: center;

      &__image {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }

      .no-content {
        width: 100%;
      }
    }
  }
}

.table-cell__line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;

  &:hover {
    overflow: visible;
    -webkit-line-clamp: none;
    display: block;
  }
}
