@import 'src/assets/scss/mixins';

.radio-label {
  display: block;
  position: relative;
  padding: 8px 0 0 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.8em;
  line-height: 100%;
  letter-spacing: 0.25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:has(.radio-input:disabled) {
    cursor: not-allowed;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $color-gray;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 7px;
      left: 7px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $color-white;
    }
  }

  .radio-input {
    -webkit-appearance: none;
    opacity: 0;
  }

  .radio-input:checked ~ .checkmark {
    background-color: $color-violet;

    &:after {
      display: block;
    }
  }

  .radio-input:checked:disabled ~ .checkmark {
    background-color: $color-gray;
    &:after {
      display: block;
    }
  }
}
