@import 'src/assets/scss/mixins';

.textarea-field {
  width: 100%;
  max-width: 450px;
  position: relative;
  border-radius: 3px;

  &__textarea {
    border: 1px solid $color-black;
    resize: none;
    height: 50px;
    padding-top: 20px;
    scroll-margin-top: 100px;

    &:disabled {
      opacity: 0.3;
    }

    &--noborder {
      border-right: 0.5px solid $color-black;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:disabled {
        border-right: 0.5px solid $color-gray;
        color: $color-gray;
        background: transparent;
      }
    }

    &--error {
      border: 1px solid $color-error;
    }

    &--multirows {
      height: initial;
    }
  }

  &__label--active {
    top: 8px;
    font-size: 0.5em;
  }
}
