@import 'src/assets/scss/mixins';

.checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-label,
.checkbox-label__circ {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 0.75em;
  width: 24px;
  height: 24px;
  line-height: 20px;
  margin-right: 10px;
  font-weight: 400;
  letter-spacing: 0.25px;

  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid $color-gray;
    background-color: $color-gray;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 3px;
  }

  &__circ {
    &:before {
      border-radius: 50%;
    }
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.checkbox-input:checked + .checkbox-label {
  &:before {
    border-color: $color-violet;
    background-color: $color-violet;
  }

  &:not(.checkbox-label__dash):after {
    content: '';
    position: absolute;
    left: 5px;
    top: 5px;
    width: 10px;
    height: 5px;
    border-left: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    transform: rotate(-45deg);
  }
}

.checkbox-input + .checkbox-label__dash {
  position: relative;
  &:before {
    border-color: $color-violet;
    background-color: $color-violet;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 2px;
    background-color: white;
  }
}

.checkbox {
  width: 100%;

  &-label__rect {
    font-size: 0.9em;
    text-align: center;
    letter-spacing: 0.5px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray;
    color: $color-white;
    margin-top: 2px;

    &:before {
      content: none;
    }
  }

  &-label__dash {
  }
}

.checkbox-input:checked + .checkbox-label__rect {
  background-color: $color-violet;

  &:after {
    content: none;
  }
}
