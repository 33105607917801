@import 'src/assets/scss/mixins';

.container {
  margin-top: 75px;

  section {
    margin: 4rem 0;
    .title {
    }
    .description {
      margin: 2rem 0;

      .base-code {
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .title {
          font-size: 1.1rem;
        }
        .content {
          margin-left: 1rem;
          display: flex;
          flex-direction: column;
          pre {
            background-color: $color-mildGray;
            padding: 0 1rem 1rem 1rem;
            border: 1px dashed black;
            position: relative;
            code {
              text-align: left;
              white-space: pre;
              word-spacing: normal;
              word-break: normal;
              word-wrap: normal;
              line-height: 1.5;
            }
          }
          .explanation {
            color: $color-red;
            margin-top: 1rem;
            font-size: 0.8rem;
          }
        }
      }
      .events {
        @extend .base-code;
        .content {
          margin: 0;
        }
      }
      .testing {
        @extend .base-code;

        ol li::marker {
          font-weight: bold;
        }
        .reminder {
          font-weight: bolder;
          margin-top: 1rem;
        }
      }

      .purchase {
        @extend .base-code;
      }
      .subscription {
        @extend .base-code;
      }
    }
  }

  .explanation {
    color: $color-red;
    margin-top: 1rem;
    font-size: 0.8rem;
  }

  .button-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 2rem;

    .progress-container {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        animation: spin 1.5s ease-in-out infinite;
      }
    }

    button {
      border: 0;
      outline: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: -0.5px;
      background-color: $color-violet;
      color: $color-white;
      padding: 12px 16px;
      border-radius: 8px;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
