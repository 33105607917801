@import 'src/assets/scss/mixins';

.action {
  scroll-margin-top: $top-bar-height;
}

.action,
.pixel {
  margin-top: 50px;

  &-inner {
    width: 100%;
    max-width: 480px;

    &__cta {
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      &-img {
        width: 190px;
        max-height: 190px;
        margin: 20px 0 20px 30px;
        position: relative;

        .cta-image {
          display: block;
          width: 100%;
          height: 100%;
          margin-bottom: 10px;
        }
      }

      &-text {
        font-size: 0.75em;
        font-weight: 400;
        text-align: center;
        color: $color-darkGray;
        position: absolute;
        bottom: -28px;
        left: 40px;
      }
    }

    &__link,
    &__discount,
    &__buttonText,
    &__thirdPartyTracking,
    &__8pixel {
      width: 100%;
      margin-top: 50px;
    }

    &__link {
    }
    &__discount {
    }
    &__buttonText {
    }

    &__conversion,
    &__install {
      margin-top: 50px;
      max-width: 420px;
    }

    &__conversion,
    &__thirdPartyTracking,
    &__8pixel {
      &-section {
        margin: 15px 0 15px 10px;
        padding: 0 0 10px 10px;
        border-left: 2px solid $color-gray;
        position: relative;
        @include size('phone') {
          margin-left: 0;
        }

        .icon-area {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 10px 0 5px 5px;

          .text-link {
            margin-left: 10px;
            font-size: 0.75em;
            font-weight: 400;
            text-align: center;
            color: $color-darkGray;
          }
        }
        .popup {
          padding-top: 1rem;
          color: $color-violet;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .button-container {
        margin: 0.5rem 0;
      }
    }

    &__8pixel {
      position: relative;
      .pixel-doc-link {
        color: $color-violet;
        text-decoration: none;
      }
      &-switch {
        padding-top: 5px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &__install {
      &-subtitle {
        margin-left: 0px;
        font-size: 0.75em;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.25px;
      }

      &-section {
        margin: 15px 0 15px 10px;
        padding: 0 0 10px 10px;
        border-left: 2px solid $color-gray;

        @include size('phone') {
          margin-left: 0;
        }

        &__link {
          display: inline-block;
          color: $color-violet;
          font-size: 0.75em;
          line-height: 100%;
          letter-spacing: 0.25px;
          font-weight: 700;
          margin-left: 15px;
          margin-top: 15px;
        }
      }
    }

    .title {
      text-transform: uppercase;
      font-size: 0.75em;
      line-height: 30px;
      font-weight: 700;
      letter-spacing: 0.25px;
    }

    .radios {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    .link-red,
    .link-violet {
      display: inline-block;
      font-size: 0.75em;
      font-weight: 700;
      letter-spacing: 0.25px;
      margin-left: 10px;
    }

    .link-red {
      color: $color-error;
    }

    .link-violet {
      color: $color-violet;
    }
  }
}
