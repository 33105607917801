@import 'src/assets/scss/mixins';

.section__title {
  width: 100%;
  border-bottom: 1px solid $color-black;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 20px;

  &--content {
    * {
      font-size: 1.333em;
      line-height: 120%;
      font-weight: 500;
      letter-spacing: 0.25px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
