@import 'src/assets/scss/mixins';

.questions {
  margin-top: 50px;
  scroll-margin-top: $top-bar-height;
  &__intro {
    width: 100%;
    max-width: 450px;
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;

    &-header {
      width: 99%;
      margin-bottom: 5px;

      &--link {
        color: $color-violet;
        font-weight: 600;
        margin-left: 5px;
      }
    }

    .list {
      padding-left: 10px;
      margin: 20px 0 0 5px;
      font-style: italic;

      &__item {
        &-emoji {
          font-style: normal;

          &:last-child {
            margin-right: 5px;
          }

          &:first-child {
            margin-left: 5px;
          }
        }

        &--accented {
          font-style: normal;
          font-weight: 700;
          margin-left: 5px;
        }
      }
    }
  }

  &__battery {
    width: 100%;
    max-width: 640px;
    margin-top: 40px;

    .questions-area {
      margin-bottom: 5px;

      &__question {
      }
    }
  }

  &__buttons {
    font-size: 0.9em;
    letter-spacing: 0.5px;
    margin: 100px 0 150px 0;
    display: flex;
    width: 100%;
    max-width: 325px;
    justify-content: space-between;

    @include size('phone') {
      width: 100%;
    }
  }
}
