@import 'src/assets/scss/mixins';

.option {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
  .data {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    gap: 5px;
    &:hover {
      background-color: #e7e7e7;
    }
    &.child {
      padding-left: 80px;
    }
  }
  .expanded,
  .closed {
    svg path {
      fill: $color-violet;
    }
    transition: all 0.15s;
  }
  .expanded {
    transform: rotate(90deg);
  }
}

.select {
  width: 100%;

  .error {
    color: $color-error;
    font-size: 0.75em;
    text-align: right;
  }
}
