@import 'src/assets/scss/mixins';

.logo-and-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;

  @include size('phone') {
    margin-bottom: 100px;
  }

  &__section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 250px;
    font-weight: bold;
    margin-top: -16px;

    .logo {
      max-height: 200px;
      width: auto;
      margin-top: -10px;
    }

    &__logo-subtitle {
      margin-top: -32px;
      text-align: center;
      font-size: 15px;
    }
  }
}
