@import 'src/assets/scss/mixins';

.blurry-container {
  position: relative;

  .backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    opacity: 0.85;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .message {
    position: absolute;
    z-index: 6;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    padding: 0 3rem;
    letter-spacing: 0.25px;
  }
}
