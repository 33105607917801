@import 'src/assets/scss/mixins';

.sponsorship {
  margin-top: 75px;

  &__unsaved {
    color: $color-black;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    gap: 5px;
    span {
      margin-top: 3px;
    }

    ul {
      padding-left: 1rem;
    }

    &-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
  }

  &__inner {
    width: 100%;

    &-caption {
      width: 480px;
      * {
        width: 480px;
      }
    }

    &-adname,
    &-media {
      scroll-margin-top: $top-bar-height;
    }
    &-sms {
      width: 100%;
      max-width: 550px;

      &__wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        &-textarea {
          width: 350px;
          flex-shrink: 1;
          margin: 0 30px 10px 0;

          @include size('phone-big') {
            width: 100%;
            margin-right: 0;
          }

          &-counter {
            width: 100%;
            max-width: 450px;
            font-size: 0.75em;
            font-weight: 400;
            text-align: right;
            color: $color-green;
            margin: 10px 0 5px 0;
          }
        }

        &-img {
          width: 170px;
          overflow: hidden;

          .sms-image {
            display: block;
            width: 100%;
            max-height: 65px;
            margin-bottom: 10px;
            margin-top: 28px;

            @include size('phone-big') {
              margin-top: 0;
            }
          }
        }
      }
    }

    &-adcover {
      width: 100%;
      max-width: 550px;

      &__upload {
        display: flex;
        flex-wrap: wrap;

        &-zone {
          width: 328px;

          @include size('phone') {
            width: 100%;
          }
        }

        &-img {
          width: 190px;
          max-height: 166px;
          margin: 20px 0 10px 30px;

          @include size('phone-big') {
            margin-left: 0;
          }

          .upload-image {
            display: block;
            width: 100%;
            height: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }

    &-video {
      margin-top: 50px;
      scroll-margin-top: $top-bar-height;

      &__subtitle {
        font-size: 0.75em;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.25px;

        .strong {
          display: block;
          font-weight: 600;
        }
        .canva-link {
          display: block;
        }
      }

      .video-and-specs {
        display: flex;
        align-items: center;
        .video-wrapper {
          max-width: 328px;
        }
      }

      .progress {
        margin-top: 20px;
        width: 100%;
        max-width: 328px;
        height: 30px;
        border: 1px solid $color-violet;

        &-bar {
          height: 100%;
          width: 25%;
          background: $color-violet;
          transition: width 0.3s ease-in-out;
        }
      }
    }

    &-tracking {
      width: 100%;
      max-width: 450px;
      margin-top: 50px;

      .tracking__inn {
        width: 100%;
        margin: 10px 0 0 15px;
        padding: 5px 40px 15px 10px;
        border-left: 2px solid $color-gray;

        @include size('phone') {
          margin-left: 0;
          padding-right: 0;
        }

        .section {
          .title {
            font-size: 0.75em;
            line-height: 30px;
            font-weight: 700;
            letter-spacing: 0.25px;
            margin-bottom: 5px;
          }
        }

        .section:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .sample-text {
      font-size: 0.75em;
      font-weight: 400;
      text-align: center;
      color: $color-darkGray;
    }
  }
}
