@import 'src/assets/scss/mixins';

.tabs {
  &__container {
    display: flex;
    border-bottom: 1px solid $color-thinGray;
  }

  &__tab {
    width: 190px;
    height: 70px;
  }
}
