@import 'src/assets/scss/mixins';

.buttons {
  margin: 65px 0 150px 0;
  display: flex;
  width: 100%;
  max-width: 435px;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include size('phone') {
    width: 100%;
  }

  &__back {
    width: 30%;
    margin-right: 30px;

    @include size('phone') {
      margin: 0 0 20px 0;
    }
  }

  // &__continue {
  //   width: 45%;

  //   &--review {
  //     width: 57%;
  //   }
  // }

  &__back,
  &__continue {
    @include size('phone') {
      width: 100%;
    }
  }
}
