@import 'src/assets/scss/mixins';

.questions {
  max-height: 260px;
  overflow-y: auto;
  margin-bottom: 40px;

  &-block {
    margin: 20px 0;

    &__header {
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      .title {
        font-weight: 600;
      }

      .category {
        color: $color-gray;
        margin-left: 10px;
      }
    }
  }
}
