@import 'src/assets/scss/mixins';

.tooltip {
  position: relative;

  &__modal {
    position: absolute;
    bottom: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    box-shadow: rgba(17, 17, 26, 0.1) 0 8px 24px, rgba(17, 17, 26, 0.1) 0 16px 56px,
      rgba(17, 17, 26, 0.1) 0 24px 80px;
    background-color: $color-white;
    border-radius: 4px;
    padding: 10px;
    letter-spacing: 0.5px;
    font-size: 0.5em;
    min-width: 120px;
    line-height: 1.5em;

    &-arrow {
      width: 0;
      height: 0;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-right: 10px solid transparent;
      border-top: 10px solid $color-white;
      border-left: 10px solid transparent;
    }
  }

  &__action {
    @extend .tooltip__modal;
    min-width: 0;
    z-index: 9999 !important;
    bottom: 100%;
    line-height: 1em;
    font-size: 0.7rem;
    text-align: center;
    font-weight: 600;
    color: $color-darkGray;
  }

  &__icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $color-violet;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    color: $color-white;
    margin-left: 10px;
  }
}
