@import 'src/assets/scss/mixins';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.login {
  * {
    font-family: 'Montserrat', sans-serif;
  }
  background-color: $color-black-new;
  color: $color-yellow;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  @include size('phone') {
    margin-bottom: 100px;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    max-width: 320px;
    padding: 0 0 1.5rem 0;

    @include size('phone') {
      margin: 30px 0 50px 0;
    }

    &__section {
      width: 100%;
      display: flex;
      justify-content: center;

      &-signup {
        font-size: 0.75em;
        line-height: 20px;
        font-weight: 400;
        margin-top: 24px;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;

        &__link {
          font-weight: 700;
          color: $color-yellow;
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }
      }

      &-reset {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        font-size: 0.75em;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: 0.25px;
        color: $color-yellow;
        text-decoration: underline;
        font-weight: bold;
      }

      &-form {
        width: 100%;

        input {
          background-color: $color-black-new;
          border-color: $color-darkGray;
          color: $color-white !important;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px #23232329;
          }
        }

        label {
          color: $color-white !important;
        }

        .form-input {
          width: 100%;
          margin-top: 12px;
        }
        .form-input-2 {
          width: 100%;
          margin-top: 24px;
        }

        .form-button {
          width: 100%;
          margin-top: 12px;
          button {
            background-color: $color-black-new;
            color: $color-yellow;
            border-color: $color-yellow;
            border-radius: 3px;
          }
        }
      }
    }

    &__contact {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      line-height: 14px;
      font-weight: 400;
      letter-spacing: 0.25px;
      color: $color-white;

      &-link {
        font-weight: 700;
        color: $color-yellow;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }

    &__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 16px;
      img {
        max-width: 150px;
      }
    }
  }
}
