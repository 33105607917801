@import 'src/assets/scss/mixins';

.campaign-menu {
  width: 100%;
  margin-top: 40px;

  &__item {
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    a {
      color: $color-violet;
    }
    span {
      max-width: 175px;
    }
  }
}

.campaign-submenu {
  padding: 0;
  list-style: none;
  margin-top: 10px;

  &__item {
    text-transform: none;
    font-size: 0.8em;
    font-weight: 400;
  }
}
