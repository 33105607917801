//Colors
$color-violet: #6672fb;
$color-bright-violet: #6d79fe;
$color-gray: #c4c4c4;
$color-lightGray: #f7f7f7;
$color-darkGray: #7f7f7f;
$color-979797: #979797;
$color-mildGray: #ebebeb;
$color-thinGray: #d8d8d8;
$color-black: #000;
$color-white: #fff;
$color-lightBlue: #fafafa;
$color-error: #ff3d55;
$color-red: #f04c4c;
$color-f03737: #f03737;
$color-green: #6dd400;
$color-37d45a: #37d45a;
$color-yellow: #fdea2f;
$color-darkGreen: #466935;
$color-navy: #1a1f71;
$color-darkRed: #cc1b1b;
$color-warning: #f8b849;
$color-black-new: #131419;

//Breakpoints

$breakpoints: (
  'phone': 480px,
  'phone-big': 600px,
  'tablet': 768px,
  'laptop': 1024px,
  'desktop': 1260px,
  'big': 1800px,
);

//Typography
$font-space-grotesk: 'Space grotesk', -apple-system, BlinkMacSystemFont, avenir next, avenir,
  helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;

//Topbar height
$top-bar-height: 70px;
