@import 'src/assets/scss/mixins';

.input-field {
  width: 100%;
  max-width: 450px;
  position: relative;
  outline: none;
  display: block;
  padding-left: 12px;
  border-radius: 3px;
  height: 50px;
  font-weight: 400;
  pointer-events: none;
  border: 1px solid $color-mildGray;

  &__label {
    font-size: 0.5em;
    color: #7f7f7f;
  }

  &__text {
    font-size: 0.85em;
    color: $color-black;
  }
}
