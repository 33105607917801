@import 'src/assets/scss/mixins';
.allocation {
  margin-top: 10px;
  width: 100%;
  max-width: 480px;

  &__title,
  &__content {
    margin-bottom: 15px;
  }

  &__content {
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;

    .bold {
      font-weight: 600;
    }
  }

  &__total {
    font-size: 1em;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.25px;
    padding-top: 15px;
    border-top: 1px solid $color-black;
  }

  &-custom {
    margin: 20px 0 30px 0;
    font-size: 1em;
  }
}
