@import 'src/assets/scss/mixins';

.form-group {
  margin-top: 48px;
  width: 100%;

  &.audiences {
    margin-top: 15px;
  }

  &__field {
    &-title {
      font-size: 1em;
      line-height: 30px;
      font-weight: 500;
      letter-spacing: 0.25px;
      display: flex;
      justify-content: space-between;
      max-width: 100%;

      .required {
        color: $color-red;
      }
    }

    &-smalltitle {
      @extend .form-group__field-subtitle;
      margin-bottom: 16px !important;
    }

    &-subtitle {
      font-size: 0.75em;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.25px;
      margin-bottom: 5px;
    }

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  &.media__format {
    max-width: 480px;
  }
}
