@import 'src/assets/scss/mixins';

.progress {
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: $color-white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 0 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  min-width: 380px;
  min-height: 48px;
  z-index: 9999;

  .progress-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: transparent;
      outline: none;
      border: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;
      color: $color-violet;
      font-weight: bold;

      &.refetching svg {
        animation: rotate 1s cubic-bezier(0.5, 1, 0.89, 1) infinite;
      }
    }
  }

  .label {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.5px;
    text-align: left;
  }

  a {
    outline: none;
    border: 0;
    margin-left: auto;
    max-height: 32px;
    padding: 11px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-violet;
    color: $color-white;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.5px;
    border-radius: 8px;
    cursor: pointer;

    ~ .clear-icon-container {
      margin-left: 0;
    }
  }

  .clear-icon-container {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
