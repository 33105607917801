@import 'src/assets/scss/mixins';

.reporting {
  &__content {
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 960px;

    &--admin {
      margin-left: 40px;
    }
  }
  &__header-pdf-download-button {
    height: 38px;
    width: 157px;
    font-size: small;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    padding: 5px;
    justify-content: space-between;

    &:hover {
      background-color: $color-bright-violet;
      border-color: $color-bright-violet;

      -webkit-transition: all 200ms ease;
      transition: all 200ms ease-in-out;
    }
  }

  &__header-container {
    color: $color-black;
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__headers {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: inline-block;
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.25px;
  }

  &__subheader {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 1.5rem;
  }

  &__targeting {
    width: 100%;
    max-width: 800px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px 20px;
    grid-template-areas:
      '. .'
      '. .'
      '. .'
      '. .';

    @include size('tablet') {
      display: block;
    }
  }

  &__media {
    width: 100%;
    max-width: 650px;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 60px;
    margin-top: 50px;

    .media-item {
      &__video {
        aspect-ratio: 9/16;
        height: 350px;
        margin-right: 20px;
        video {
          object-fit: cover;
        }
      }

      &__cta {
        width: 150px;

        .img__wrap {
          width: 100%;

          .img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }

    .title {
      margin-bottom: 10px;
    }
  }

  &__incentive {
    .logo-container {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      &__picture {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border: 1px solid $color-black;
        border-radius: 20px;
        overflow: hidden;

        .img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
        }
      }

      &__name {
        font-size: 0.8em;
        line-height: 100%;
        font-weight: 400;
        margin-right: 30px;
      }
    }

    &__grid-overall {
      max-width: 800px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 20px;

      @include size('tablet') {
        display: block;
      }
    }

    .detail-container {
      max-width: 800px;
      font-size: 0.85em;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0.5px;
      margin-bottom: 50px;

      .accented {
        font-weight: 600;
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    &-image {
      width: 310px;
      height: 320px;
      margin: 0 53px 15px 53px;
      object-fit: contain;
    }

    &-title {
      width: 391px;
      height: 80px;
      margin: 0 26px 10px 0;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 1px;
    }

    &-subtitle {
      height: 30px;
      margin: 10px 0 0;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1px;
    }
  }

  &__overview-header {
    margin-bottom: 2rem;
  }

  &__hero-container {
    max-width: 960px;
    height: auto;
  }

  &__hero-divider {
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
  }

  &__routes-container {
    width: 100%;
  }
  .grid-item {
    @include size('tablet') {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}
