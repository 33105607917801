@import 'src/assets/scss/mixins';

.table-wrap {
  overflow-x: auto;
  .table {
    width: 100%;
    display: table;
    overflow-x: auto;

    &-head {
      background-color: yellowgreen;
      width: 100%;
      display: table-header-group;

      &__cell {
        display: table-cell;
        text-align: center;
        padding: 20px 10px;
        font-size: 0.85em;
        font-weight: 600;
        line-height: 14px;
        min-width: 115px;
        position: sticky;
        top: 0;
        z-index: 2;
        border-bottom: 1px solid $color-black;
        background-color: $color-white;
      }
    }

    &-body {
      display: table-row-group;
    }

    &-row {
      display: table-row;
      width: 100%;
    }

    &-cell {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      padding: 10px;
      font-size: 0.85em;
      font-weight: 400;
      line-height: 14px;
      border-bottom: 1px solid $color-black;
    }
  }
}

.empty-body {
  display: flex;
  justify-content: center;
  padding: 16px;
  color: $color-darkGray;
}

.table-cell__date {
  padding: 5px 0 0 0;
  margin: 0;
  font-size: 0.85rem;
}

.table-footer {
  border-top: 1px solid $color-thinGray;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
  min-height: 130px;

  @include size('phone') {
    margin-bottom: 150px;
    min-height: auto;
  }

  .results-select {
    width: 200px;

    @include size('phone') {
      width: 100%;
      order: 2;
    }
  }

  .pagination {
    width: 30%;

    @include size('phone') {
      width: 100%;
      order: 1;
      margin-bottom: 20px;
    }

    &-inner {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      &__arrow {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        cursor: pointer;

        &:hover > svg {
          color: $color-violet;
        }
      }

      &__button,
      &__button-active {
        height: 100%;
        font-size: 1em;
        font-weight: 700;
        margin: 0 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &-active {
          color: $color-violet;
        }

        &:hover {
          color: $color-violet;
        }
      }
    }
  }
}

.alert {
  margin-top: 1rem;
  font-style: italic;
  color: $color-violet;
}
