@import 'src/assets/scss/mixins';

.spinner {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    width: 300px;
    height: 20%;

    &__svg {
      display: block;
      margin: 0 auto;
      transform: rotate(90deg);
      max-width: 15em;
      border-radius: 3px;
      fill: none;
      stroke-linecap: round;
      stroke-width: 10%;

      use {
        stroke: $color-violet;
        animation: a 2s linear infinite;
      }
    }
  }
}

@keyframes a {
  to {
    stroke-dashoffset: 0;
  }
}
