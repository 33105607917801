@import 'src/assets/scss/mixins';

.container {
  margin-top: 75px;

  section {
    margin: 4rem 0;
    .title {
    }
    .description {
      margin: 2rem 0;

      .pixel-doc-link {
        color: $color-violet;
        text-decoration: none;
      }

      .list-item {
        margin-bottom: 0.7rem;
      }

      .base-code {
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .title {
          font-size: 1.1rem;
        }
        .content {
          margin-left: 1rem;
          display: flex;
          flex-direction: column;
          pre {
            background-color: $color-mildGray;
            padding: 0 1rem 1rem 1rem;
            border: 1px dashed black;
            position: relative;
            code {
              text-align: left;
              white-space: pre;
              word-spacing: normal;
              word-break: normal;
              word-wrap: normal;
              line-height: 1.5;
            }
          }
          .explanation {
            color: $color-red;
            margin-top: 1rem;
            font-size: 0.8rem;
          }
        }
      }
      .events {
        @extend .base-code;
        .content {
          margin: 0;
          .names {
            margin: 1rem 0;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }
      .purchase {
        @extend .base-code;
      }
      .subscription {
        @extend .base-code;
      }
    }
  }
}
