@import 'src/assets/scss/mixins';

.audience {
  width: 100%;

  &__subtitle {
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-bottom: 20px;
  }

  &__table {
    width: 100%;
    height: 291px;
    max-height: 291px;
    overflow-y: hidden;

    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 5px;
      border-bottom: 1px solid $color-black;
      font-weight: 400;

      &__responses {
        width: 45%;
      }

      &__tags {
        width: 55%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-link {
          color: $color-violet;
          font-size: 0.75em;
          line-height: 20px;
          font-weight: 400;
          letter-spacing: 0.25px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &-content {
      display: flex;
      height: 100%;

      &__responses {
        overflow-y: auto;
        width: 45%;
        height: 100%;
        max-height: 250px;
        border-right: 1px solid $color-gray;

        .response__row,
        .response__row--active {
          width: 100%;
          min-height: 50px;
          padding: 12px 7px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $color-gray;

          &-text {
            font-weight: 300;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 85%;
          }

          &-amount {
            width: 25px;
            height: 25px;
            background-color: $color-violet;
            color: $color-white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.75em;
            padding-top: 2px;
          }

          &--active {
            background-color: $color-mildGray;
            border-bottom: 1px solid $color-mildGray;
          }
        }
      }

      &__tags {
        width: 55%;
        max-height: 250px;
        background-color: $color-mildGray;
        overflow-y: auto;
        padding: 5px;
      }
    }
  }
}
