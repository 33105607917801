@import 'src/assets/scss/mixins';

.confirm {
  &__text {
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  &__buttons {
    margin: 40px 0 12px 0;
    display: flex;
    justify-content: center;
  }
}
