@import 'src/assets/scss/mixins';

.section__subtitle {
  &--content {
    // max-width: 450px;
    width: 100%;
    font-size: 0.75em;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }
}
