@import 'src/assets/scss/mixins';

html {
  font-family: $font-space-grotesk;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

body {
  margin: 0;
  padding: 0;
  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-space-grotesk;
  margin: 0;
}

h3 {
  font-weight: 500;
}

h6 {
  font-weight: 600;
}

input[type='file'] {
  outline: none;
}

input[type='text'],
input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  outline: none;
  display: block;
  padding: 7px 12px 0;
  border-radius: 3px;
  height: 50px;
  font-size: 0.85em;
  font-weight: 400;
  color: $color-black;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  & ~ label {
    position: absolute;
    left: 13px;
    top: 20px;
    font-size: 0.75em;
    line-height: 100%;
    letter-spacing: 0.25px;
    transition: 0.2s ease all;
    color: $color-darkGray;
  }

  &:focus {
    ~ label {
      top: 8px;
      font-size: 0.5em;
    }
  }
}

#summary-link.active {
  color: $color-violet;
}

input[type='date'] {
  color: $color-darkGray;
  padding: 15px 10px;
  position: relative;
  background: $color-white;
  -webkit-appearance: none;

  &:focus {
    color: $color-black;
  }

  &:after {
    content: '';
    display: block;
    background-size: 100% 100%;
    background: url('../../assets/images/date-picker.svg') no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 8px;
    top: 11px;
  }

  &:hover:after {
    background: url('../../assets/images/date-picker-violet.svg') no-repeat;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
    height: 30px;
    width: 30px;
    outline: none;
  }

  &::-webkit-clear-button {
    display: none;
  }
}

.react-datepicker__input-container > input {
  padding-top: 0;
}

option {
  height: 50px;
  line-height: 5px;
}

thead,
tbody,
tfoot {
  width: 100%;
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}

.capitalized {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */

* {
  scrollbar-width: auto;
  scrollbar-color: #dedede #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: $color-gray;
  border-radius: 5px;
  border: 0px none #ffffff;
}
