@import 'src/assets/scss/mixins';

.btn {
  outline: none;
  height: 50px;
  display: block;
  padding: 0 40px;
  border: 2px solid $color-violet;
  background: $color-white;
  color: $color-violet;
  font-size: 0.9em;
  letter-spacing: 0.5px;

  &__disabled {
    opacity: 0.3;
  }

  &__primary {
    padding: 0 40px;
    color: $color-white;
    background: $color-violet;
  }

  &__secondary {
    width: 120px;
    color: $color-white;
    background: $color-gray;
    border: 1px solid $color-gray;
  }

  &__black {
    padding: 0 20px;
    color: $color-black;
    border: 1px solid $color-black;
  }

  &__gray {
    background: $color-gray;
    border: 1px solid $color-gray;
    color: $color-white;
  }

  &__filter {
    border-radius: 3px;
    padding: 0 25px;
    color: $color-white;
    font-size: 0.75em;
    background-color: $color-violet;

    &-disabled {
      background-color: $color-darkGray;
      opacity: 1;
      border: 2px solid $color-darkGray;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fullwidth {
  width: 100%;
}
