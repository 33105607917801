@import 'src/assets/scss/mixins';

.stripe-container {
  height: 50px;
  border: 1px solid black;
  border-radius: 3px;
  padding: 15px 10px;
}

.stripe-container--error {
  height: 50px;
  border: 1px solid $color-red;
  border-radius: 3px;
  padding: 15px 10px;
}
