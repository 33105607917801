@import 'src/assets/scss/mixins';

.table {
  margin-top: 8px;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #ddd;
    text-transform: capitalize;
  }

  td {
    padding: 8px;

    &:first-child {
      font-weight: 500;
    }
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-block: 4px;
    padding-inline: 12px;
    text-align: left;
    background-color: $color-violet;
    color: white;
  }
}
