@import 'src/assets/scss/mixins';

.topbar {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $color-white;
  padding: 1rem 0;
  max-height: $top-bar-height;
  position: fixed;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 15;

  &__inner {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px 0 35px;

    @include size('tablet') {
      justify-content: space-between;
      margin: 0;
      padding: 0 20px;
      margin-right: 2rem;
    }

    &-home {
      display: none;
      margin-top: 40px;

      @include size('tablet') {
        display: block;
        margin-top: 0;
      }

      &__link {
        color: $color-violet;
        padding: 10px 10px 10px 0;
      }
    }
  }
}
