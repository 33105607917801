@import 'src/assets/scss/mixins';

.menu-modal {
  position: absolute;
  width: 240px;
  left: 180px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  z-index: 5;
  padding: 1.1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .option {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-weight: 500;
    &:hover {
      color: $color-violet;
      svg * {
        fill: $color-violet;
      }
    }
    &.disabled {
      pointer-events: none;
      * {
        color: $color-gray;
        fill: $color-gray;
      }
      &:hover {
        * {
          color: $color-gray;
          fill: $color-gray;
        }
      }
    }
  }
}
