.stripe {
  &-success-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      padding: 0;
      margin: 0 auto 20px 0;
      font-size: 1.25em;
      font-weight: 400;
      letter-spacing: 0.75px;
    }

    &__text {
      padding: 7px 0 20px 0;
      margin: 0 auto 0 0;
    }
  }
}
