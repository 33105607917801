@import 'src/assets/scss/mixins';

.value__container {
  width: 40%;
  min-width: 225px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .icon {
    background-color: transparent;
    line-height: 80%;
  }

  .text {
    width: 50%;
    align-self: center;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 0.75em;
    line-height: 1.5em;
    text-align: left;
    padding-left: 10px;
    white-space: nowrap;
  }
}
