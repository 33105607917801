@import 'src/assets/scss/mixins';

.select {
  width: 100%;

  .error {
    color: $color-error;
    font-size: 0.75em;
    text-align: right;
  }
}
