@import 'src/assets/scss/mixins';

.button {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-mildGray;
  border: none;
  cursor: pointer;

  &:hover {
    background: $color-violet;

    & svg {
      fill: $color-white;
    }
  }

  &-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
