@import 'src/assets/scss/mixins';

.radio {
  width: 100%;
  padding: 0 5px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &__label {
    display: block;
  }
}
