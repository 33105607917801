@import 'src/assets/scss/mixins';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 8500;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  @include size('phone-big') {
    justify-content: center;
    align-items: center;
  }
}

.modal {
  position: relative;
  z-index: 102;
  width: 500px;
  height: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  background: $color-white;
  border-radius: 20px;
  transform: scale(0);

  &__billing {
    width: 540px;
  }

  &__video {
    min-width: 500px;
    width: 50%;
    padding: 47px 47px 41px 47px;
  }

  @include size('phone-big') {
    width: 90%;
  }

  &--active {
    max-height: 90%;
    overflow-y: auto;
    transform: scale(1);
    animation: blowUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &-close {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .close__btn {
      width: 30px;
      height: 30px;
      border: 2px solid $color-error;
      border-radius: 50%;
      position: relative;

      &:hover {
        background-color: $color-error;

        &:before,
        &:after {
          background-color: $color-white;
        }
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        background-color: $color-error;
        transform: rotate(45deg);
      }

      &:before {
        width: 2px;
        left: 12px;
        top: 5px;
        bottom: 5px;
      }

      &:after {
        height: 2px;
        top: 12px;
        left: 5px;
        right: 5px;
      }

      &-black {
        border-color: $color-black;

        &:hover {
          background-color: $color-black;
        }

        &:before,
        &:after {
          background-color: $color-black;
        }
      }
      &-video {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }

  &-title {
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.75px;
    margin-bottom: 20px;

    &__error {
      color: $color-error;
    }
  }

  &-body {
    font-size: 1em;
    line-height: 1.3em;
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__confirm {
      margin: 30px 0;

      &--fullwidth {
        width: 100%;
      }
    }

    &__cancel {
      padding: 15px 30px;
      color: $color-error;
      cursor: pointer;

      &--blue-text {
        color: $color-violet;
      }
    }
  }
}

@-webkit-keyframes blowUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blowUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
