@import 'src/assets/scss/mixins';

.filters {
  width: 100%;
  margin-top: 10px;

  &-row {
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;

    &__title {
      font-size: 0.75em;
      margin-bottom: 15px;
    }

    &__battery {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 150px;

        &:not(:last-child) {
          margin-right: 20px;

          @include size('phone') {
            margin-right: unset;
            margin-top: 15px;
          }
        }

        @include size('phone') {
          width: 100%;
        }

        &--long {
          width: 320px;
          margin-right: 20px;

          @include size('phone') {
            width: 100%;
            margin-right: unset;
          }
        }

        &-age__all {
          display: flex;
          justify-content: center;
          align-items: center;

          @include size('phone') {
            width: 100%;
            margin-right: unset;
          }
        }

        &-date__section {
          width: 100%;
          max-width: 320px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          @include size('phone') {
            width: 100%;
            display: block;
            max-width: unset;
          }

          .item-to {
            padding-top: 20px;
            font-size: 0.75em;
            margin-left: 5px;
            margin-right: 5px;

            @include size('phone') {
              text-align: center;
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 1.5rem;

      .link-text {
        font-size: 0.75em;
        text-decoration: underline;
        padding: 10px 10px 10px 0;
        cursor: pointer;

        @include size('phone') {
          order: 1;
        }
      }

      &-filter,
      &-report {
        width: auto;
        display: flex;
        align-items: center;
        padding-bottom: 1rem;

        @include size('tablet') {
          margin-top: 15px;
        }

        &__submit {
          width: 150px;
          margin-right: 20px;
        }
      }

      &-report {
        justify-content: flex-end;
        margin-right: 20px;

        .csv-container {
          .csv-link {
            display: flex;
            align-items: flex-start;
            text-decoration: none;
            color: $color-black;
          }
          .download-options {
            position: absolute;
            transform: translate(-10px);
            list-style-type: none;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $color-white;
            padding: 1rem 3rem 1rem 1rem;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            display: none;
            li {
              padding: 3px 0;
              width: 100%;
              font-size: 0.75em;
              cursor: pointer;
            }
          }

          &.show-options .download-options {
            display: block;
          }
        }

        @include size('tablet') {
          justify-content: flex-start;
        }

        &__icon {
          cursor: pointer;

          @include size('phone') {
            order: 0;
            margin-right: 10px;
          }

          &:hover {
            color: $color-violet;
          }
        }
      }
    }
  }

  &__date-from-wrap,
  &__date-to-wrap {
    position: relative;
    min-width: 80px;
    > svg {
      position: absolute;
      right: 19px;
      top: 14px;
      pointer-events: none;
    }

    &:hover {
      > svg {
        color: $color-violet;
      }
    }
  }
}
