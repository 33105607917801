@import 'src/assets/scss/mixins';

.cards-battery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .payment-card {
    height: 153px;
    width: 47%;
    margin-bottom: 20px;

    &:nth-child(odd) {
      margin-right: 20px;
    }

    @include size('phone-big') {
      width: 50%;
      margin-right: 20px;
    }

    @include size('phone') {
      width: 90%;
      margin-right: 0;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }

    &__add {
      width: 47%;
      height: 153px;
      border: 4px solid $color-gray;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @include size('phone-big') {
        width: 50%;
        margin-right: 20px;
      }

      @include size('phone') {
        width: 90%;
        margin-right: 0;
      }

      &--central {
        color: $color-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: 0.75em;
          line-height: 100%;
          letter-spacing: 0.25px;
          margin-top: 5px;
        }
      }
    }
  }
}

.payment-methods {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include size('phone-big') {
    justify-content: center;
  }

  &__card {
    height: 153px;
    width: 47%;
    margin-bottom: 20px;

    &:nth-child(odd) {
      margin-right: 20px;
    }

    @include size('phone-big') {
      width: 50%;
      margin-right: 20px;
    }

    @include size('phone') {
      width: 90%;
      margin-right: 0;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }

    &-add,
    &-add-bacs {
      width: 47%;
      height: 153px;
      border: 4px solid $color-gray;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 5px;
      margin-right: 20px;

      @include size('phone-big') {
        width: 50%;
      }

      @include size('phone') {
        width: 90%;
        margin-right: 0;
      }

      &-central {
        color: $color-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: 0.75em;
          line-height: 100%;
          letter-spacing: 0.25px;
          margin-top: 5px;
        }
      }
    }

    &-add-bacs {
      margin-right: 0px;

      @include size('phone-big') {
        margin-left: 0px;
        margin-top: 24px;
        margin-right: 20px;
      }

      @include size('phone') {
        width: 90%;
        margin-right: 0;
      }
    }
  }
}
