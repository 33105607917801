@import 'src/assets/scss/mixins';

.link,
.disabled {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.link {
  color: $color-black;
  padding: 7px 0;
  z-index: 9;
  background-color: $color-white;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    margin-left: 3px;
    color: $color-black;
    margin-top: 2px;
    &:first-child {
      margin-left: 0px;
    }
  }

  &--active {
    color: $color-violet;
    span {
      color: $color-violet;
    }
  }
}

.disabled {
  color: $color-gray;
  padding: 7px 0;
  span {
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    margin-left: 3px;
    color: $color-black;
  }
}
