@import 'src/assets/scss/mixins';

.account-info {
  margin-top: 48px;

  &__section {
    max-width: 480px;

    &-checkboxes {
      max-width: 450px;
      min-height: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-checkbox {
        max-width: 450px;
        margin-top: 16px;
      }
    }

    &-input {
      margin-top: 1rem;
    }

    &-checkboxes--error {
      min-height: 8rem;
    }

    .currency {
      width: 100%;
      font-size: 0.75em;
      font-weight: 400;
      letter-spacing: 0.75px;
      text-align: right;

      &-code {
        color: $color-violet;
        font-weight: 700;
        margin-left: 5px;
      }
    }

    .vat-inner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &__specs {
        flex-grow: 2;
      }

      &__upload {
        flex-grow: 1;
        // margin-right: 10px;
      }
    }
  }

  &__button {
    margin: 50px 0 150px 0;
  }

  &__logo-inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__upload {
      margin-right: 20px;

      @include size('tablet') {
        width: 100%;
      }
    }

    &__specs {
      @include size('tablet') {
        width: 100%;
      }
    }
  }
}
