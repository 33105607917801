@import 'src/assets/scss/mixins';

.home-link {
  padding-left: 12px;

  @include size('tablet') {
    display: none;
  }

  &__container {
    color: $color-black;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      color: $color-violet;
    }

    &--icon {
      margin-right: 5px;
    }

    &--label {
      font-size: 0.75em;
    }
  }
}
