.container {
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 450px;
    svg {
      margin-bottom: 10px;
    }
  }
}
