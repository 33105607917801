@import 'src/assets/scss/mixins';

.budget {
  max-width: 450px;
  scroll-margin-top: $top-bar-height;
}

.charity {
  width: 90%;

  &__inn {
    width: 100%;
    margin: 10px 0 0 15px;
    padding: 5px 15px 15px 10px;
    border-left: 2px solid $color-gray;

    &-section {
      .title {
        font-size: 0.75em;
        line-height: 30px;
        font-weight: 700;
        letter-spacing: 0.25px;
        margin-bottom: 5px;
      }

      .add {
        font-size: 0.75em;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.25px;
        margin-top: 10px;
      }
    }

    &-section:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.popup {
  color: $color-violet;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.video {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__checkboxes-wrap {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
  }

  &__checkbox {
    width: 180px;
    // margin-right: 1rem;
    // margin-top: 1rem;
  }

  .custom-incentive {
    font-size: 12px;
    color: $color-violet;
  }
}
