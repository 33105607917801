@import 'src/assets/scss/mixins';

.children {
  position: relative;
}

.pop-up-container {
  position: absolute;
  display: inline;
  background-color: white;
  color: black;
  box-shadow: 1px 1px 8px rgb(199, 199, 199);
  border-radius: 20px;
  min-width: 250px;
  min-height: 40px;
  visibility: visible;
  -webkit-animation: popup 0.5s;
  animation: popup 0.5s;
  backface-visibility: hidden;
  z-index: 1;
}
.pop-up-container::after {
  content: ' ';
  position: absolute;
}

.right-default {
  top: 50%;
  transform: translateY(-50%);
  left: 105%;
  color: black;
  background-color: white;
}
.right-default::after {
  top: 40%; /* At the bottom of the tooltip */
  left: 0%;
  transform: rotate(45deg);
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent white white;
}

.right-primary {
  top: 50%;
  transform: translateY(-50%);
  left: 105%;
  color: white;
  background-color: $color-violet;
}
.right-primary::after {
  top: 40%; /* At the bottom of the tooltip */
  left: 0%;
  transform: rotate(45deg);
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent $color-violet $color-violet;
  border-radius: 2px;
}

.bottom {
  margin-top: 110px;
}
.bottom::after {
  top: 0%; /* At the bottom of the tooltip */
  left: 20%;
  transform: rotate(45deg);
  margin-top: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: white transparent transparent white;
}

.left {
  margin-bottom: 10px;
  margin-left: -320px;
}
.left::after {
  top: 50%; /* At the bottom of the tooltip */
  left: 97%;
  transform: rotate(45deg);
  margin-left: 0px;
  border-width: 7px;
  border-style: solid;
  border-color: white white transparent transparent;
}

.top {
  margin-bottom: 110px;
  margin-left: -50px;
}
.top::after {
  top: 90%; /* At the bottom of the tooltip */
  left: 50%;
  transform: rotate(45deg);
  margin-left: 0px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent white white transparent;
}

.pop-up-content {
  display: flex;
  align-items: space-between;
  flex-direction: column;
  padding: 16px 0px 16px 0px;
}

.buttons-container {
  padding: auto;
  margin-top: 10px;
}

.ok-btn {
  color: white;
  background-color: $color-green;
  border: 0;
  border-radius: 2px;
  min-width: 40px;
  padding: 5px;
}

.cancel-btn {
  color: white;
  background-color: $color-red;
  border-radius: 2px;
  border: 0;
  margin-left: 20px;
  border-radius: 2px;
  min-width: 60px;
  padding: 5px;
}

.ok-btn:hover {
  background-color: rgb(137, 206, 137);
}

.cancel-btn:hover {
  background-color: #f06565;
}

.message {
  font-weight: 400;
}

@keyframes popup {
  0% {
    transform: scale(0.8) translateY(-50%);
  }
  30% {
    transform: scale(1.02) translateY(-50%);
  }
  100% {
    transform: scale(1) translateY(-50%);
  }
}
