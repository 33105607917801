@import 'src/assets/scss/mixins';

.signup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &-side {
    width: 250px;
    padding-left: 40px;

    @include size('tablet') {
      width: 90%;
      padding-left: 20px;
    }

    &__title {
      position: sticky;
      top: 20px;

      .message {
        font-size: 0.8em;
        margin-top: 15px;

        .login-link {
          display: block;
          color: $color-violet;
          font-weight: bold;
          margin-top: 5px;
          text-decoration: none;
        }
      }
    }
  }

  &-main {
    width: 70%;
    margin-top: 90px;
    padding: 0 20px;

    @include size('tablet') {
      width: 100%;
      margin-top: 30px;
    }

    &__logo {
      width: 100%;
      max-width: 550px;

      .logo-inner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &__upload {
          margin-right: 20px;

          @include size('tablet') {
            width: 100%;
          }
        }

        &__specs {
          @include size('tablet') {
            width: 100%;
          }
        }
      }
    }

    &__section {
      width: 100%;
      max-width: 480px;

      .subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
      }

      .currency {
        display: flex;
        font-size: 1em;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.25px;
        font-weight: bold;

        &-code {
          color: $color-violet;
          font-weight: 700;
          margin-left: 5px;
        }
      }

      .link {
        color: $color-violet;
        font-weight: 700;
        letter-spacing: 0.1;
      }
    }

    &__button {
      margin: 50px 0 150px 0;
    }
  }
}
