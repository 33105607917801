.list {
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 400px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__see-all {
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    cursor: pointer;
    display: flex;
    margin-top: 20px;
    margin-bottom: 6px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:first-child {
      margin-top: 0;
    }
  }

  &__text {
    &-container {
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-self: stretch;
      margin-bottom: 5px;
    }

    &-counter,
    &-text {
      font-size: 0.75em;
      font-weight: 400;
      text-align: center;
      letter-spacing: 1px;
      text-decoration: none;
      text-transform: none;
    }

    &-counter-capital {
      text-align: start;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__bar {
    &-container {
      cursor: pointer;
      width: 100%;
      height: 13px;
      border-radius: 10px;
      background-color: #f2f2f2;
    }

    &-percentage {
      cursor: pointer;
      max-width: 0%;
      height: 13px;
      border-radius: 10px;
      background-color: #6672fb;
      transition: max-width 1000ms ease-in-out, background-color 100ms linear;

      &:hover {
        background-color: rgb(127, 139, 255);
      }
    }
  }
}
