@import 'src/assets/scss/mixins';

.hints-battery {
  margin-top: 20px;
  font-size: 0.75em;
  font-weight: 400;
  letter-spacing: 0.75px;

  &__hint {
    margin: 10px 0;
    display: flex;
    align-items: center;
    height: 20px;

    .bullet {
      margin-right: 10px;
    }
  }
}
