@import 'src/assets/scss/mixins';

.thirdParty {
  margin: 15px 0 15px 10px;
  padding: 0 0 10px 10px;
  border-left: 2px solid $color-gray;
  position: relative;
  @include size('phone') {
    margin-left: 0;
  }

  .popup {
    padding-top: 1rem;
    color: $color-violet;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }

  .title {
    text-transform: uppercase;
    font-size: 0.75em;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 0.25px;
  }

  button {
    padding: 0;
    margin-top: 1rem;
    width: initial;
  }
}
