@import 'src/assets/scss/mixins';

.section {
  width: 100%;
  margin-bottom: 40px;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__block {
      display: flex;
      align-items: center;

      &-drag {
        background-image: url('../../assets/images/dragging-bars.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 15px;
        height: 30px;
        cursor: pointer;
        margin-right: 5px;

        &--disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }

      &-text {
        margin-top: 2px;
      }
    }

    &__audience-link {
      font-size: 0.75em;
      font-weight: 500;
      line-height: 16px;
      text-decoration: underline;
      color: $color-violet;
      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }

  &-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;

    &__block {
      display: flex;
      align-items: center;

      &-drag {
        background-image: url('../../assets/images/dragging-bars.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 15px;
        height: 30px;
        cursor: pointer;
        margin-right: 5px;

        &--disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }

      &-text {
        margin-top: 2px;
        line-height: 20px;
      }
    }

    &__warning {
      font-size: 0.75em;
      font-weight: 400;
      line-height: 20px;
    }

    &__audience-link {
      font-size: 0.75em;
      font-weight: 500;
      line-height: 16px;
      text-decoration: underline;
      color: $color-violet;
      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }

  .input-area {
    display: flex;
    margin-top: 3px;

    .select-area {
      width: 100%;
      max-width: 190px;
    }
  }

  .options-area {
    &__option {
      border-radius: 4px;
      margin-top: 10px;
      display: flex;

      &-buttons {
        width: 100%;
        max-width: 190px;
        display: flex;
      }
    }
  }

  .options-area__dragging {
    background: $color-mildGray;
  }

  &-notifications {
    color: $color-green;
    font-size: 0.75em;
    margin: 10px 0;
  }

  &-buttons {
    width: 30%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
