@import 'src/assets/scss/mixins';

.sponsor-set {
  margin-top: 75px;

  &__unsaved {
    color: $color-black;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    gap: 5px;
    span {
      margin-top: 3px;
    }

    ul {
      padding-left: 1rem;
    }

    &-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
  }

  &__inner {
    width: 100%;
    max-width: 450px;
    scroll-margin-top: $top-bar-height;

    &-audience__wrapper {
      margin-top: 50px;
      scroll-margin-top: $top-bar-height;
    }

    &-pixel__wrapper {
      position: relative;
      scroll-margin-top: $top-bar-height;
      margin-top: 30px;
      .pixel-doc-link {
        color: $color-violet;
        text-decoration: none;
      }
      .title {
        text-transform: uppercase;
        font-size: 0.75em;
        line-height: 30px;
        font-weight: 700;
        letter-spacing: 0.25px;
      }
      .radios {
        margin-top: 0.5rem;
        margin-bottom: 2rem;
      }
      .pixel-section {
        margin-top: 0.5rem;
        position: relative;
        &__inner {
          position: relative;
        }
      }
      .switch {
        padding-top: 5px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .tab-section {
      display: flex;
      border-bottom: 1px solid $color-thinGray;

      .button-wrapper {
        flex-basis: 50%;
      }
    }
  }
}
