.container {
  display: flex;
  flex-direction: column;
  .wrapper {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}
