@import '~react-toastify/dist/ReactToastify.css';
@import '../mixins';

.Toastify__toast-container {
  z-index: 9000;

  @include size('tablet') {
    padding: 20px 30px 20px 20px;
  }

  &-notifications {
    margin-left: 0;
    width: 100%;
    position: unset;

    @include size('tablet') {
      transform: none;
      padding: 0;
    }

    @include size('phone') {
      margin-bottom: 10px;
    }
  }
}

.Toastify__toast {
  font-family: 'Space grotesk', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding-left: 15px;

  &-notifications {
    background-color: $color-white;
    color: $color-black;
    min-height: 50px;
    font-size: 0.75em;
    line-height: 1.45em;
    font-weight: 400;
    position: relative;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.41);

    @include size('phone') {
      margin-bottom: 10px;
    }
  }

  &--error {
    background-color: $color-error;
    color: $color-white;
  }

  &--success {
    background-color: $color-green;
    color: $color-white;
  }

  &--error-notifications,
  &--success-notifications {
    background-color: $color-white;
    color: $color-black;
    min-height: 50px;
    font-size: 0.75em;
    line-height: 1.45em;
    font-weight: 400;
    position: relative;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.41);
    cursor: unset;

    @include size('phone') {
      margin-bottom: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
    }

    &--body {
      padding-left: 20px;
    }
  }

  &--error-notifications {
    &:before {
      background-color: $color-error;
    }
  }

  &--success-notifications {
    &:before {
      background-color: $color-green;
    }
  }

  &--default {
    color: $color-black;
  }

  &--info {
    background-color: $color-violet;
    color: $color-white;
  }
}

.Toastify__close-button {
  color: $color-black;
}

.Toastify__toast--info {
  &-attention-notification {
    &:before {
      background-color: $color-yellow;
    }
  }

  &-notification {
    &:before {
      background-color: $color-violet;
    }
  }

  &-error-notification {
    &:before {
      background-color: $color-error;
    }
  }

  &-success-notification {
    &:before {
      background-color: $color-green;
    }
  }

  &-error-notification,
  &-success-notification,
  &-attention-notification,
  &-notification {
    background-color: $color-white;
    color: $color-black;
    min-height: 50px;
    font-size: 0.75em;
    line-height: 1.45em;
    font-weight: 400;
    position: relative;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.41);
    cursor: unset;

    @include size('phone') {
      margin-bottom: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
    }

    &--body {
      padding-left: 20px;
    }
  }
}
