@import 'src/assets/scss/mixins';

.modal-text {
  font-size: 0.75em;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.modal-input__title {
  margin-left: 0;
  margin-top: 30px;
  font-size: 0.75em;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;

  &:first-child {
    margin-top: 10px;
  }
}

.modal-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;
}
