@import 'src/assets/scss/mixins';

.new-audience {
  width: 100%;

  .gender {
    width: 100%;

    &__error {
      color: $color-error;
      font-size: 0.75em;
      margin-top: 20px;
    }

    &__wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 390px;

      .gender-ceil {
        flex-basis: 50%;
        margin-bottom: 10px;
      }
    }
  }

  .age,
  .pixelAudiences {
    width: 100%;

    &__error {
      color: $color-error;
      font-size: 0.75em;
    }

    &__wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 390px;

      .age-ceil,
      .pixelAudience-ceil {
        flex-basis: 50%;
        margin-bottom: 10px;
      }
    }
  }

  .locations,
  .interests,
  .passions,
  .pixelAudiences,
  .frequency-cap {
    width: 100%;
    position: relative !important;
    &-switch {
      position: absolute;
      top: 5px;
      right: 0;
    }
    &-subtitle {
      width: 100%;
      font-size: 0.75em;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.25px;
      margin-bottom: 5px;
      text-align: justify;

      &__accented {
        font-weight: 700;
      }
    }

    &-targeted {
      margin-top: 10px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .frequency-cap-item {
      margin-top: 15px;
    }
  }

  .custom-audiences {
    margin-top: 48px;
    &__subtitle {
      width: 100%;
      font-size: 0.75em;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.25px;
      text-align: justify;
      margin-bottom: 5px;
    }
  }
}
