@import 'src/assets/scss/mixins';

.form-funds {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 22rem;

  &__charge-error {
    color: $color-error;
    margin: 0.5rem 0 0 0;
  }

  &__sum,
  &__method {
    width: 100%;
  }

  &__title {
    padding: 0;
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.75px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .invoice_link {
      padding: 0;
      margin: 0;
      color: $color-violet;
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
    }
  }

  &__divider {
    width: 100%;
    border-bottom: 1px solid $color-thinGray;
    margin: 1.5rem 0;
  }

  &__subtotal,
  &__vat,
  &__stripe-fee,
  &__total {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0 0 10px 0;

    &-sum {
      min-width: 70px;
      margin-left: 15px;
      text-align: right;
    }
  }

  &__total {
    font-weight: 600;
  }

  &__time {
    font-size: 0.75rem;
    margin-bottom: 1.3rem;
  }
}
