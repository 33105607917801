@import 'src/assets/scss/mixins';

.specs {
  color: $color-darkGray;
  max-width: 400px;
  width: 100%;
  border-left: 2px solid $color-mildGray;
  padding: 10px;
  margin-left: 10px;

  @include size('phone') {
    margin-left: 0;
  }

  &-title {
    margin-bottom: 10px;
  }

  &-file {
    display: flex;
    align-items: center;
    font-size: 0.75em;
    line-height: 100%;
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &__type {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      flex-shrink: 0;
      flex-grow: 0;

      &-icon {
        margin-right: 10px;
      }

      &-title {
      }
    }

    &__values {
    }
  }
}
