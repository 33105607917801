@import 'src/assets/scss/mixins';

.demographics {
  &-header {
    font-size: 1.333em;
    line-height: 100%;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-top: 50px;
  }

  &-content {
    width: 100%;
    max-width: 960px;
    margin-top: -1rem;

    &__flex--medium {
      padding: 5px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .flex-item {
        width: 49%;

        @include size('tablet-graph') {
          width: 100%;

          &:not(:first-child) {
            margin-top: 20px;
          }
        }

        .no-data {
          min-height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.3333em;
        }
      }
    }

    &:last-child {
      margin-bottom: 100px;
    }
  }
}
