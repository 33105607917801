@import 'src/assets/scss/mixins';

.chart {
  &__container {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  &__title-container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex: 0 auto;
    max-width: 90%;

    &--full {
      width: 100%;
      max-width: 100%;

      .link {
        color: $color-violet;
        font-size: 1em;
        font-weight: 700;
        letter-spacing: 1px;
        text-decoration: underline;
        margin-top: 5px;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
    line-height: 1.5em;
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.75px;
    text-decoration: none;
  }

  &__question {
    display: flex;
    flex-direction: column;

    &-title {
      text-decoration: none;
      font-size: 1.25em;
      font-weight: 500;
      line-height: 24px;
    }

    &-text {
      line-height: 1.5em;
      cursor: pointer;
      font-size: 0.85em;
      font-weight: 400;
      letter-spacing: 0.75px;
      text-decoration: none;
      margin-top: 0.8rem;
    }
  }

  &__chart-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
