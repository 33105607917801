@import 'src/assets/scss/mixins';

.modal-text,
.modal-input__title {
  margin-left: 0;
  font-size: 0.75em;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.modal-text {
  &__link {
    text-decoration: underline;
    cursor: pointer;
    color: $color-violet;
    font-weight: 600;
  }
}

.modal-input__title {
  margin-top: 40px;
}

.modal-button {
  margin: 60px auto 20px auto;
}
