.content-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  left: 0;
  .content {
    max-width: 500px;
    text-align: center;
    .spinner-container {
      height: 150px;
    }
    .svg-container {
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 5rem;
        width: 5rem;
        margin-bottom: 1.5rem;
      }
    }
    .details {
      text-align: center;
      font-size: 1.2rem;
    }
    .buttons-container {
      margin-top: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
