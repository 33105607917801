@import 'src/assets/scss/mixins';

.form-brand-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 11.5rem;

  &__section {
    max-width: 450px;

    &-input {
      margin-top: 1rem;
    }
  }

  &__section-checkboxes {
    max-width: 450px;
    margin-top: 2.5rem;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__btn {
    margin: 5rem auto 1rem auto;
  }

  &__section-checkboxes--error {
    min-height: 8rem;
  }
}
