@import 'src/assets/scss/mixins';

.sponsorshipset-menu {
  width: 100%;
  &__item {
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    &-title {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      width: 80%;
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      span {
        max-width: 105px;
      }
      .adset-icon {
        display: flex;
        align-items: center;
      }
      &.active {
        * {
          color: $color-violet;
        }
        .adset-icon {
          svg {
            rect {
              stroke: $color-violet;
            }
            path {
              fill: $color-violet;
            }
          }
        }
        .expand-icon {
          svg {
            stroke: transparent;
            path {
              stroke: transparent;
              fill: $color-violet;
            }
          }
        }
      }
      &.disabled {
        * {
          color: $color-gray;
        }
        .adset-icon {
          svg {
            rect {
              stroke: $color-gray;
            }
            path {
              fill: $color-gray;
            }
          }
        }
        .expand-icon {
          svg {
            stroke: transparent;
            path {
              stroke: transparent;
              fill: $color-gray;
            }
          }
        }
      }
    }
    .open {
      display: flex;
      svg {
        transition: all 0.15s;
        transform: rotate(90deg);
      }
    }
    .closed {
      display: flex;
      svg {
        transition: all 0.15s;
        transform: rotate(0);
      }
    }
  }
}

.sponsorshipset-submenu {
  padding: 0;
  list-style: none;
  margin-top: 0px;
  margin-left: 25px;
  margin-bottom: 5px;
  color: $color-black;

  &__item {
    text-transform: none;
    font-size: 0.8em;
    font-weight: 400;
  }
}
