@import 'src/assets/scss/mixins';

.manage-content {
  margin-top: 20px;
  width: auto;
  min-height: 100vh;

  &__title {
    font-size: 1.333em;
    font-weight: 400;
    color: $color-black;
    margin-top: 20px;
  }

  &__form {
    margin: 3rem 0 7rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include size('desktop-mid') {
      .search-bar {
        margin-top: 1.5rem;
      }
    }

    &-search-area {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      .filters {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-right: 1.5rem;
        .dropdown-select {
          width: 200px;
          * {
            margin-top: 0;
          }
        }
        .clear-filters {
          background-color: transparent;
          border: 0;
          text-decoration: underline;
          align-self: flex-end;
          margin-bottom: 1rem;
          font-size: 0.8rem;
        }
      }

      .search-bar {
        display: flex;
        align-items: center;
        position: relative;
        box-shadow: 0 0 0 1px $color-black;
        border-radius: 4px;
        overflow: hidden;
        width: 450px;
        .input-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          input {
            border: 0;
          }
          button {
            margin-top: 5px;
            background-color: transparent;
            border: 0;
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }

        .search-button {
          border: 0;
          background-color: $color-violet;
          padding: 1rem;
          cursor: pointer;
          align-self: stretch;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 7px;
          width: 25%;
          color: white;
          svg {
            fill: white;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-table-area {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    &-pagination-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include size('phone') {
        margin-bottom: 150px;
        min-height: auto;
      }

      .results-select {
        width: 200px;

        @include size('phone') {
          width: 100%;
          order: 2;
        }
      }

      .pagination {
        width: 30%;

        @include size('phone') {
          width: 100%;
          order: 1;
          margin-bottom: 20px;
        }

        &-inner {
          display: flex;
          justify-content: flex-end;
          margin-top: 15px;

          &__arrow {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            cursor: pointer;

            &:hover > svg {
              color: $color-violet;
            }
          }

          &__button,
          &__button-active {
            height: 100%;
            font-size: 1em;
            font-weight: 700;
            margin: 0 10px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &-active {
              color: $color-violet;
            }

            &:hover {
              color: $color-violet;
            }
          }
        }
      }
    }

    &-tabs-section {
      display: flex;
      border-bottom: 1px solid $color-thinGray;

      .tab-wrapper {
        width: 33%;
        max-width: 190px;
      }
      .panel-section {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      }
    }
  }
}

.no-data__info {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}
