@import '~react-datepicker/dist/react-datepicker.css';
@import '../mixins';

.react-datepicker {
  font-family: $font-space-grotesk;
  display: flex;
}

.react-datepicker-popper:has(.react-datepicker__time-container) {
  min-width: 420px;
}

.react-datepicker__header {
  padding-top: 20px;
}

.react-datepicker__navigation {
  top: 15px;
}

.react-datepicker__day-name {
  width: 2.5rem;
}

.react-datepicker__day {
  width: 2.5rem;
  line-height: 2.5rem;
}

.react-datepicker__current-month {
  font-size: 1.25rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $color-violet;
  outline: none;
}
.react-datepicker-wrapper {
  max-width: 450px;

  @include size('phone-big') {
    width: 100%;
  }
}

.react-datepicker__year-select {
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
  font-size: 16px;
}

.react-datepicker__custom-input-gray {
  border: 1px solid hsl(0, 0%, 80%);

  &--disabled {
    border: 1px solid hsl(0, 0%, 80%);
    opacity: 0.3;
  }

  &:hover {
    border: 1px solid $color-violet;
  }

  &:focus {
    border: 1px solid $color-violet;
  }
}

.react-datepicker__custom-input {
  border: 1px solid $color-black;

  &--disabled {
    border: 1px solid $color-black;
    opacity: 0.3;
  }
}

.react-datepicker__custom-input-error {
  border: 1px solid red;
}

.react-datepicker__input-container {
  > input {
    color: $color-darkGray;
    padding-right: 45px;
  }
}
